import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faBoxOpen,
  faCodeBranch,
  faFillDrip,
  faFilter,
  faFlask,
  faTrashAlt,
  faVials,
} from '@fortawesome/pro-regular-svg-icons';
import { faCodeMerge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ActionButton from 'src/components/ActionButton';
import { BatchCardPreview } from 'src/components/BatchCardPreview';
import ContainerQRCodes from 'src/components/ContainerQRCodes';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import Tooltip from 'src/components/Tooltip';
import SieveModal from 'src/pages/material-batch/[uuid]/sections/_sieve_modal';
import BatchPowderQualityWarningModal from 'src/pages/material-container/sections/_powder_quality_warning_modal';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES, MATERIAL_CONTAINER_STATUSES, PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../utils/routes';

const ContainerActionPage = ({ user }) => {
  const [batch, setBatch] = useState(null);
  const [allContainers, setAllContainers] = useState([]);
  const [containersFetching, setContainersFetching] = useState(true);
  const [containersFetchError, setContainersFetchError] = useState(null);
  const [loadedPrinter, setLoadedPrinter] = useState(null);
  const [subLocation, setSubLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBatchQualityWarningModal, setIsBatchQualityWarningModal] = useState(false);
  const [isSieveModalShown, setIsSieveModalShown] = useState(false);
  const [batchQualityWarningAction, setBatchQualityWarningAction] = useState(null);
  const pageRedirects = {
    [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: `/scan?action=${MATERIAL_BATCH_ACTIONS.MACHINE_LOAD}&entity=${API_RESOURCES.PRINTER}&material-batch=${batch?.uri}&initialBatchAction=true${allContainers.length > 1 ? '&batchMultipleContainers=true' : ''}`,
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: `/scan?action=${MATERIAL_BATCH_ACTIONS.BLEND_BATCHES}&entity=${API_RESOURCES.MATERIAL_BATCH}&material-batch=${batch?.uri}`,
  };
  const batchHasPowderQualityWarningStatus = MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(batch?.powder_quality);

  const tooltipMessagesForActions = {
    [MATERIAL_BATCH_ACTIONS.RELOCATE]: 'relocation.',
    [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: 'splitting.',
    [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: 'scrapping.',
    [MATERIAL_BATCH_ACTIONS.SIEVE]: 'sieving.',
    [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: 'blending.',
  };

  const { openActionPanel } = useActionPanelStore();

  const { addToast } = useToasts();

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const batchUuid = params.get('batch');

    const fetchData = async () => {
      try {
        const batchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUuid}/`).json();
        setBatch(batchData);

        if (batchData) {
          const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;
          const { resources: nonEmptyContainers } = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
            searchParams: {
              'filter[current_batch]': batchData.uri,
              'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
              'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
            },
          }).json();

          setAllContainers(nonEmptyContainers);
          setContainersFetching(false);

          if (batchData.at_machine) {
            const loadedPrinterData = await api.get(`${API_RESOURCES.PRINTER}/${getUuid(batchData.at_machine)}/`).json();
            setLoadedPrinter(loadedPrinterData);
          }
          const subLocationData = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchData.sub_location)}/`).json();
          setSubLocation(subLocationData);
        }

        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
        setContainersFetching(false);
        setContainersFetchError(e);
      }
    };

    fetchData();
  }, []);


  const handleShowQRCodes = () => {
    openActionPanel({
      title: 'Print QR Codes',
      content: <ContainerQRCodes
        containers={allContainers}
        backUri={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}
        forResource={batch.uri}
      />,
    });
  };

  const renderLoadedPrinterTooltip = (action) => {
    return (
      <Tooltip
        id="relocation_batch_when_printer"
        placement="top"
        trigger={
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-info ml-2 relocate-batch-badge-info"
          />
        }
      >
        <div>
          The Batch is loaded to the printer <strong>{loadedPrinter.name}</strong>.{' '}
          Please unload the Batch before {tooltipMessagesForActions[action]}
        </div>
      </Tooltip>
    );
  };

  if (isLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={batch?.id} />
        </main>
      </>
    );
  }

  const isBatchDone = batch.status === MATERIAL_BATCH_STATUSES.DONE;

  const splitButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="splitButton">
      <Link to={getRouteURI(routes.materialBatchAction,
        { uuid: getUuid(batch.uri) },
        {
          type: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
          ...(allContainers.length > 1 && { batchMultipleContainers: true }),
        }
      )}
      >
        <button
          type="button"
          disabled={isBatchDone || !!loadedPrinter}
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100"
        >
          <FontAwesomeIcon icon={faCodeBranch} size="3x" className="d-block m-auto" />
          Split
        </button>
      </Link>
      {loadedPrinter && renderLoadedPrinterTooltip(MATERIAL_BATCH_ACTIONS.SPLIT_BATCH)}
    </div>
  );

  const scrapButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="scrapButton">
      <Link to={getRouteURI(routes.materialBatchAction,
        { uuid: getUuid(batch.uri) },
        {
          type: MATERIAL_BATCH_ACTIONS.SCRAP_BATCH,
        }
      )}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-btn-text mm-ph-100 mm-pw-100"
          disabled={isBatchDone || !!loadedPrinter}
        >
          <FontAwesomeIcon icon={faTrashAlt} size="3x" className="d-block m-auto" />
          Scrap
        </button>
      </Link>
      {loadedPrinter && renderLoadedPrinterTooltip(MATERIAL_BATCH_ACTIONS.SCRAP_BATCH)}
    </div>
  );

  const sieveButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="sieveButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(batch.uri) }, { type: MATERIAL_BATCH_ACTIONS.SIEVE })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={isBatchDone || !!loadedPrinter}
        >
          <FontAwesomeIcon icon={faFilter} size="3x" className="d-block m-auto" />
          Sieve
        </button>
      </Link>
      {loadedPrinter && renderLoadedPrinterTooltip(MATERIAL_BATCH_ACTIONS.SIEVE)}
    </div>
  );

  const relocateButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="relocateButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(batch.uri) }, { type: MATERIAL_BATCH_ACTIONS.RELOCATE })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={isBatchDone || !!loadedPrinter}
        >
          <FontAwesomeIcon icon={faBoxOpen} size="3x" className="d-block m-auto" />
          Relocate
        </button>
      </Link>
      {loadedPrinter && renderLoadedPrinterTooltip(MATERIAL_BATCH_ACTIONS.RELOCATE)}
    </div>
  );

  const treatmentButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="treatmentButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(batch.uri) }, { type: MATERIAL_BATCH_ACTIONS.TREATMENT })}
      >
        <button
          disabled
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
        >
          <FontAwesomeIcon icon={faFlask} size="3x" className="d-block m-auto" />
          Treatment
        </button>
      </Link>
    </div>
  );

  const testButton = (
    <div className="batch-option-button" id="testButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(batch.uri) }, { type: MATERIAL_BATCH_ACTIONS.TEST })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100"
          disabled={isBatchDone}
        >
          <FontAwesomeIcon icon={faVials} size="3x" className="d-block m-auto" />
          Test
        </button>
      </Link>
    </div>
  );


  const handleShowBatchPowderQualityWarningModal = (type) => {
    setBatchQualityWarningAction(type);
    setIsBatchQualityWarningModal(true);
  };

  const renderActionButton = (type, redirectLink) => {
    const BUTTON_TYPES = {
      [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: {
        button: (
          <ActionButton
            className="btn-primary"
            id="loadButton"
            disabled={isBatchDone}
            onClick={batchHasPowderQualityWarningStatus ?
              () => handleShowBatchPowderQualityWarningModal(MATERIAL_BATCH_ACTIONS.MACHINE_LOAD) :
              () => {
              }}
          >
            <FontAwesomeIcon icon={faFillDrip} size="3x" className="d-block m-auto" />
            Load
          </ActionButton>
        ),
      },
      [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: {
        button: (
          <ActionButton
            skipDefaultClassName
            className="btn-outline-secondary"
            id="blendButton"
            disabled={isBatchDone || !!loadedPrinter}
            onClick={batchHasPowderQualityWarningStatus ?
              () => handleShowBatchPowderQualityWarningModal(MATERIAL_BATCH_ACTIONS.BLEND_BATCHES) :
              () => {
              }}
          >
            <FontAwesomeIcon icon={faCodeMerge} size="3x" className="d-block m-auto" />
            Blend
          </ActionButton>
        ),
      },
    };

    return (
      <div
        className="batch-option-button relocate-warning-wrapper"
        id={type === MATERIAL_BATCH_ACTIONS.MACHINE_LOAD ? 'loadButton' : 'blendButton'}
      >
        {
          batchHasPowderQualityWarningStatus ? (
            BUTTON_TYPES[type].button
          ) : (
            <>
              <Link
                to={redirectLink}
              >
                {BUTTON_TYPES[type].button}
              </Link>
              {loadedPrinter && type !== MATERIAL_BATCH_ACTIONS.MACHINE_LOAD && renderLoadedPrinterTooltip(MATERIAL_BATCH_ACTIONS.BLEND_BATCHES)}
            </>
          )
        }
      </div>
    );
  };

  const handleRedirectToAction = (skipSieving = false) => {
    if (skipSieving) {
      return navigate(pageRedirects[batchQualityWarningAction] + '&skipSieving=true');
    }
    addToast(`Batch ${getShortUuid(batch.uuid)} was successfully Sieved`, { appearance: 'success' });
    return navigate(pageRedirects[batchQualityWarningAction]);
  };

  return (
    <>
      <Header
        title="Material Batch Actions" back="/scan" user={user}
        onPrintQRCodesClick={handleShowQRCodes} />
      <main role="main" className="text-center">
        <div className="mb30">
          <BatchCardPreview
            isExpanded
            batch={batch}
            allContainers={allContainers}
            containersFetching={containersFetching}
            containersFetchError={containersFetchError}
            subLocation={subLocation?.name}
            shouldShowBatchLink={false}
            loadedPrinter={loadedPrinter}
          />
        </div>
        <div className="batch-options-button-wrapper">
          {splitButton}
          {scrapButton}
          {treatmentButton}
          {testButton}
          {renderActionButton(
            MATERIAL_BATCH_ACTIONS.BLEND_BATCHES,
            pageRedirects[MATERIAL_BATCH_ACTIONS.BLEND_BATCHES])}
          {sieveButton}
          {relocateButton}
          {renderActionButton(
            MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
            pageRedirects[MATERIAL_BATCH_ACTIONS.MACHINE_LOAD])}
        </div>
      </main>

      {
        isBatchQualityWarningModal && (
          <BatchPowderQualityWarningModal
            action={batchQualityWarningAction}
            additionalAction={() => setIsSieveModalShown(true)}
            onSubmit={() => handleRedirectToAction(true)}
            onClose={() => setIsBatchQualityWarningModal(false)} />
        )
      }

      {
        isSieveModalShown && (
          <SieveModal
            initialBatch={batch}
            hideModal={() => setIsSieveModalShown(false)}
            redirectToNextAction={handleRedirectToAction}
          />
        )
      }
    </>
  );

};

/* eslint-disable camelcase */
ContainerActionPage.propTypes = {
  batch: PropTypes.shape({
    material_name: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })).isRequired,
    material_in_containers: PropTypes.bool,
    containers: PropTypes.arrayOf(
      PropTypes.string
    ),
    uri: PropTypes.string,
    quantity: PropTypes.number,
    units: PropTypes.string,
    status: PropTypes.string,
    location_name: PropTypes.string,
  }),
  error: PropTypes.shape({}),
  user: userPropType,
  subLocation: PropTypes.shape({
    name: PropTypes.string,
  }),
};

ContainerActionPage.defaultProps = {
  batch: {},
  error: null,
  user: null,
  subLocation: {},
};

export default ContainerActionPage;

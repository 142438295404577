import { faExclamationTriangle, faObjectGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Alert from 'src/components/alert';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import Modal from 'src/components/modal';
import NotFound from 'src/components/not-found';
import ActionPage from 'src/pages/material-batch/[uuid]/action/_action-wrapper';
import SieveModal from 'src/pages/material-batch/[uuid]/sections/_sieve_modal';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES,
} from 'src/utils/constants';
import { checkSupportedMaterialsMismatchForPrinter } from 'src/utils/printerUtils';
import { pluralWord } from 'src/utils/stringUtils';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';
import BatchPowderQualityWarningModal from '../../material-container/sections/_powder_quality_warning_modal';

const AlreadyLoadedPage = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: printerUUID } = useParams();
  const { batch: batchUri, initialBatchAction } = Object.fromEntries(searchParams.entries()) ?? {};
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [isBatchQualityWarningModal, setIsBatchQualityWarningModal] = useState(false);
  const [isSieveModalShown, setIsSieveModalShown] = useState(false);
  const [pageState, setPageState] = useState({
    id: printerUUID,
    printer: null,
    printerType: null,
    loadedBatch: null,
    batchToLoad: null,
    locations: null,
    subLocations: null,
  });
  const batchHasPowderQualityWarningStatus = MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES.includes(pageState?.batchToLoad?.powder_quality);

  const { addToast } = useToasts();
  const navigate = useNavigate();


  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const printer = await api.get(`${API_RESOURCES.PRINTER}/${printerUUID}/`).json();
      const printerType = printer?.printer_type && await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printer.printer_type)}/`).json();
      const batchToLoad = await api.get(batchUri, { prefixUrl: false }).json();

      const { resources: batchesInPrinter } = await api.get(
        `${API_RESOURCES.MATERIAL_BATCH}/`,
        {
          searchParams: {
            'filter[at_machine]': printer.uri,
          },
        }
      ).json();

      const subLocationsUris = printer && batchToLoad ?
        [...new Set([printer.sub_location, batchToLoad.sub_location])] :
        [];

      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`, {
        searchParams: {
          'filter[uri]': subLocationsUris.join(','),
        },
      }).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`, {
        searchParams: {
          'filter[uri]': printer.location,
        },
      }).json();
      setPageState({
        id: printerUUID,
        printer,
        printerType,
        // Backend allows not more than 1 batch loaded at a time. Using [0] to get that
        loadedBatch: batchesInPrinter[0] || {},
        batchToLoad,
        locations,
        subLocations,
      });

    } catch (error) {
      if (error.name === 'HTTPError' && error.response.status === 404) {
        setError({ name: error.name, status: error.response && error.response.status });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [printerUUID]);

  useEffect(() => {
    if (batchHasPowderQualityWarningStatus) {
      setIsBatchQualityWarningModal(true);
    }
  }, [batchHasPowderQualityWarningStatus]);

  const unsupportedMaterials = checkSupportedMaterialsMismatchForPrinter(pageState.batchToLoad, pageState.printerType);

  const hasDifferentLocations =
    pageState.batchToLoad?.location !== pageState.printer?.location &&
    pageState.batchToLoad?.sub_location !== pageState.printer?.sub_location;


  if (error) {
    return (
      <>
        <Header title="Not Found" back="/scan" user={user} />
        <main role="main" className="text-center">
          <NotFound id={pageState.id} />
        </main>
      </>
    );
  }

  const onActionRelocate = async (batchUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: MATERIAL_BATCH_ACTIONS.RELOCATE,
        source_batch: batchUri,
      };

      setSubmitting(true);
      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };

  const onActionTopOff = async (currentlyLoadedBatchUri, batchToLoadUri, printerUri) => {
    try {
      const payload = {
        /* eslint-disable camelcase */
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF,
        source_batch: currentlyLoadedBatchUri,
        metadata: {
          printer: printerUri,
          batch_to_load: batchToLoadUri,
        },
        /* eslint-enable camelcase */
      };

      setSubmitting(true);
      await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      });
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      return;
    }

    setSubmitting(false);
    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(batchToLoadUri) },
      {
        action: MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF,
        printerUri: printerUri,
        initialBatchUUID: getUuid(batchUri),
      }));

    return new Promise(() => {
    });
  };

  const handleSubmitMachineTopOff = () => {
    if (hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    return onActionTopOff(pageState.loadedBatch.uri, pageState.batchToLoad.uri, pageState.printer.uri);
  };

  const onModalConfirm = async () => {
    await onActionRelocate(pageState.batchToLoad.uri, pageState.printer.location, pageState.printer.sub_location);
    return onActionTopOff(pageState.loadedBatch.uri, pageState.batchToLoad.uri, pageState.printer.uri);
  };

  const onModalDismiss = () => setShowRelocateModal(false);


  const renderLocationMismatchText = () => {
    const batchSubLocation = pageState.subLocations.find(
      (subLocation) => subLocation.uri === pageState.batchToLoad.sub_location
    );
    const printerSubLocation = pageState.subLocations.find(
      (subLocation) => subLocation.uri === pageState.printer.sub_location
    );
    const printerLocation = pageState.locations.find(
      (location) => location.uri === pageState.printer.location
    );

    return (
      <>
        You are trying to Machine Top Off but there is a Locations mismatch. The Printer
        ({getShortUuid(pageState.printer.uri)}) is in {printerLocation?.name} ({printerSubLocation?.name}) and the
        Batch ({getShortUuid(pageState.batchToLoad.uri)}) is
        in {pageState.batchToLoad.location_name} ({batchSubLocation?.name}).

        Before Machine Load, the Batch ({getShortUuid(pageState.batchToLoad.uri)}) will be Relocated to the matching
        Locations. Are you sure you would like to relocate and continue?
      </>
    );
  };

  const handleNextAction = async (isSieveAction = false) => {
    if (isSieveAction) {
      addToast(`Batch ${getShortUuid(pageState.batchToLoad.uuid)} was successfully Sieved`, { appearance: 'success' });
      await fetchInitialData();
    }
    setIsSieveModalShown(false);
    setIsBatchQualityWarningModal(false);

  };

  const handleRedirectToInitialBatch = () => {
    if (initialBatchAction) {

      return navigate(getRouteURI(routes.materialContainer,
        {},
        {
          batch: pageState.batchToLoad.uuid,
        }));
    }

    return navigate(getRouteURI(routes.printerMaterial,
      {},
      {
        uuid: getUuid(pageState.printer.uri),
      }));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !pageState.printer) {
    return <NotFound id={pageState.id} />;
  }

  return (
    <ActionPage
      id={pageState.id}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF}
    >
      <h1 className="header-margin">
        <span className="badge badge-info text-wrap">{pageState.printer.name}</span> is already loaded
      </h1>
      <h4 className="header-margin">
        Our system indicates that this machine is already loaded with&nbsp;
        <span className="badge badge-secondary">
          {getShortUuid(pageState.loadedBatch.uri)}
        </span>.
        Are you topping up?
      </h4>
      {
        unsupportedMaterials.length && (
          <Alert name="qr-instructions" variant="warning" className="text-left d-flex align-items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr15 warning-color-small" />
            <p className="mb0">
              Printer <span className="font-bold">{pageState.printer?.name}</span> does not support&nbsp;
              {pluralWord('material', unsupportedMaterials)}&nbsp;
              {unsupportedMaterials.map((material, index) => (
                <span key={material} className="font-bold">
                  <FontAwesomeIcon icon={faObjectGroup} className="mr-1" />
                  {material}
                  {index < unsupportedMaterials.length - 1 ? ', ' : ''}
                </span>
              ))}
            </p>
          </Alert>
        )
      }
      <div className="row btn-group">
        <div className="optionButtons">
          <button
            type="submit"
            className="btn btn-primary btn-action mr-1"
            disabled={isSubmitting || unsupportedMaterials.length}
            onClick={handleSubmitMachineTopOff}
          >
            {
              batchHasPowderQualityWarningStatus && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip id="batch-quality-statustooltip">
                      Consider sieving this batch or use a Batch that is New or already Sieved.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 warning-color" />
                </OverlayTrigger>
              )
            }
            Confirm
          </button>
        </div>
        <div className="optionButtons">
          <Link to={getRouteURI(routes.printerMaterial, { uuid: pageState.id })}>
            <button type="button" className="btn btn-default btn-action">
              Cancel
            </button>
          </Link>
        </div>
      </div>

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate"
        onConfirm={onModalConfirm}
        onDismiss={onModalDismiss}
      >
        {renderLocationMismatchText()}
      </Modal>

      {
        isBatchQualityWarningModal && (
          <BatchPowderQualityWarningModal
            action={MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF}
            additionalAction={() => setIsSieveModalShown(true)}
            redirectAction={handleRedirectToInitialBatch}
            onSubmit={handleNextAction}
            onClose={() => setIsBatchQualityWarningModal(false)} />
        )
      }

      {
        isSieveModalShown && (
          <SieveModal
            initialBatch={pageState.batchToLoad}
            hideModal={() => setIsSieveModalShown(false)}
            redirectToNextAction={() => handleNextAction(true)}
          />
        )
      }

    </ActionPage>
  );
};

AlreadyLoadedPage.propTypes = {
  user: userPropType,
};

AlreadyLoadedPage.defaultProps = {
  user: null,
};

export default AlreadyLoadedPage;

import create from 'zustand';

const useActionPanelStore = create(set => ({
  isVisible: false,
  title: '',
  content: null, // For dynamic component rendering
  panelId: null,
  isSubmitting: false,
  successMessage: '',
  errorMessage: '',

  openActionPanel: ({ title, content, panelId, ...props }) =>
    set({
      isVisible: true,
      title,
      content,
      panelId,
      ...props,
    }),

  closeActionPanel: () =>
    set({
      isVisible: false,
      title: '',
      content: null,
      isSubmitting: false,
      successMessage: '',
      errorMessage: '',
      panelId: null,
    }),

  setActionPanelProps: props =>
    set(state => ({
      ...state,
      ...props,
    })),
}));

export default useActionPanelStore;

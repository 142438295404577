import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BatchCardExpanded } from 'src/components/BatchCardExpanded';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { api } from 'src/utils/api';
import { API_RESOURCES, BATCH_CARD_TABS } from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

const BatchCardExpandedContainer = ({
  batch,
  allContainers,
  containersFetching,
  containersFetchError,
  togglePreview,
  subLocation,
  shouldShowBatchLink,
  loadedPrinter,
  hideContainers,
}) => {
  const [materialLots, setMaterialLots] = useState([]);
  const [allLocationsByUri, setAllLocationsByUri] = useState({});
  const [allSubLocationsByUri, setAllSubLocationsByUri] = useState({});
  const [cardState, setCardState] = useState({
    [BATCH_CARD_TABS.LOTS]: {
      fetching: true,
      error: false,
    },
  });

  const isMobileDevice = useMediaQuery('(min-width: 300px) and (max-width: 600px)');

  const fetchLocationDetails = async (uris, resource) => {
    const fetchPromises = [...uris].map(async uri => {
      try {
        const response = await api.get(`${resource}/${getUuid(uri)}/`);
        return [uri, await response.json()];
      } catch (error) {
        console.error(`An error occurred while fetching Location URI: ${uri}:`, error);
        return null;
      }
    });

    const details = await Promise.all(fetchPromises);
    return Object.fromEntries(details);
  };

  const fetchResource = async (resourceKey, route, searchParams) => {
    // Set fetching for the particular Card Resource
    setCardState(prev => ({
      ...prev,
      [resourceKey]: {
        ...prev[resourceKey],
        fetching: true,
        error: false,
      },
    }));

    try {
      const response = await api.get(route, {
        searchParams,
      });
      const { resources: allResources } = await response.json();

      // Successfully fetched data, update state for the Card Resource
      setCardState(prev => ({
        ...prev,
        [resourceKey]: {
          fetching: false,
          error: false,
        },
      }));

      return allResources;
    } catch (error) {
      console.error(`An error occurred while fetching ${resourceKey}:`, error);
      // Set error state for the particular Card Resource
      setCardState(prev => ({
        ...prev,
        [resourceKey]: {
          fetching: false,
          error: true,
        },
      }));

      return null;
    }
  };

  // Fetch containers and material lots
  const fetchAllData = async () => {
    const materialLots = await fetchResource(BATCH_CARD_TABS.LOTS, API_RESOURCES.MATERIAL_LOT, {
      'filter[uri]': batch.material_lots.join(','),
    });

    // Return resources with fallback to an empty array if undefined
    return {
      allContainers: allContainers || [],
      materialLots: materialLots || [],
    };
  };

  // Extract unique location and sub-location URIs
  const extractUniqueLocationUris = (permanentContainers, allMaterialLots) => {
    const locationUris = new Set(
      [
        ...permanentContainers.map(container => container.location),
        ...allMaterialLots.map(lot => lot.location),
      ].filter(Boolean)
    );

    const subLocationUris = new Set(
      [
        ...permanentContainers.map(container => container.sub_location),
        ...allMaterialLots.map(lot => lot.sub_location),
      ].filter(Boolean)
    );

    return { locationUris, subLocationUris };
  };

  // Initialize data fetching and state updates
  const getInitialData = async () => {
    const { allContainers, materialLots } = await fetchAllData();
    const { locationUris, subLocationUris } = extractUniqueLocationUris(
      allContainers,
      materialLots
    );

    setMaterialLots(materialLots);

    const locations = await fetchLocationDetails(locationUris, API_RESOURCES.LOCATION);
    const subLocations = await fetchLocationDetails(subLocationUris, API_RESOURCES.SUB_LOCATION);

    setAllLocationsByUri(locations);
    setAllSubLocationsByUri(subLocations);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <BatchCardExpanded
      batch={batch}
      hideContainers={hideContainers}
      togglePreview={togglePreview}
      subLocation={subLocation}
      shouldShowBatchLink={shouldShowBatchLink}
      cardState={cardState}
      allContainers={allContainers}
      containersFetching={containersFetching}
      containersFetchError={containersFetchError}
      allLocationsByUri={allLocationsByUri}
      allSubLocationsByUri={allSubLocationsByUri}
      materialLots={materialLots}
      isMobileDevice={isMobileDevice}
      loadedPrinter={loadedPrinter}
    />
  );
};

BatchCardExpandedContainer.defaultProps = {
  shouldShowBatchLink: true,
  loadedPrinter: null,
  hideContainers: false,
};

BatchCardExpandedContainer.propTypes = {
  /* eslint-disable camelcase */
  batch: PropTypes.shape({
    uri: PropTypes.string,
    material_lots: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  subLocation: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  togglePreview: PropTypes.func.isRequired,
  shouldShowBatchLink: PropTypes.bool,
  loadedPrinter: PropTypes.shape({}),
  allContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containersFetching: PropTypes.bool.isRequired,
  containersFetchError: PropTypes.bool.isRequired,
  hideContainers: PropTypes.bool,
};

export default BatchCardExpandedContainer;

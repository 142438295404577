import 'src/styles/index.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { CSSTransition } from 'react-transition-group';
import ActionPanel from 'src/components/action-panel';
import ActionProgress from 'src/components/action-progress';
import Loader from 'src/components/loader';
import FeaturesContext from 'src/context/FeaturesContext';
import UserContext from 'src/context/UserContext';
import config from 'src/utils/config';

import ActionProvider from './components/action-provider';
import InstallPrompt from './components/install-prompt';
import SentryErrorBoundary from './components/sentry-error-boundary';
import RouterComponent from './routes';
import { getFeatures, getSession } from './utils/auth';
import Sentry, { init as initSentry } from './utils/sentry';

initSentry({
  dsn: config.sentryDsn,
  environment: config.environment,
  release: config.commit,
  sampleRate: config.sentrySampleRate,
});

const App = () => {
  const location = useLocation();
  const [installPrompt, setInstallPrompt] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { setFeatures } = useContext(FeaturesContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.uri,
          email: user.username,
          name: user.name,
        });
      });
    }

    const initialize = event => {
      event.preventDefault();

      let hidePrompt = false;
      const travelerPage = location.pathname.startsWith('/traveler');

      try {
        const storageValue = window.localStorage.getItem('installPrompt');
        hidePrompt = storageValue === 'hidden';
      } catch (_) {
        // ignore
      }

      if (hidePrompt || travelerPage) {
        return;
      }
      setInstallPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', initialize);
    return () => {
      window.removeEventListener('beforeinstallprompt', initialize);
    };
  }, []);

  const getInitialData = async () => {
    let user = null;
    let features = [];

    try {
      user = await getSession();
      setUser(user);
    } catch (error) {
      Sentry.captureException(error);
    }

    if (user) {
      try {
        features = await getFeatures();
        setFeatures(features);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    getInitialData().finally(() => setIsLoading(false));
  }, []);

  const hideInstallPrompt = () => {
    setInstallPrompt(false);

    try {
      window.localStorage.setItem('installPrompt', 'hidden');
    } catch (_) {
      // ignore
    }
  };

  const promptToInstall = () => {
    installPrompt.prompt();
    installPrompt.userChoice.then(() => {
      hideInstallPrompt();
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Authentise QR</title>
      </Helmet>
      <SentryErrorBoundary userName={user?.name || ''} userEmail={user?.username || ''}>
        <ToastProvider autoDismiss autoDismissTimeout={4000}>
          <div className='main-content-container'>
            <ActionProvider>
              <RouterComponent user={user} />
              <ActionPanel />
            </ActionProvider>
          </div>
          <ActionProgress />
        </ToastProvider>
        <CSSTransition in={Boolean(installPrompt)} timeout={200} classNames='slide'>
          <InstallPrompt onConfirm={promptToInstall} onDismiss={hideInstallPrompt} />
        </CSSTransition>
      </SentryErrorBoundary>
    </>
  );
};

export default hot(App);

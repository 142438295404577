import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Alert from 'src/components/alert';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import ContainerQuantitySingleInput from 'src/components/ContainerQuantitySingleInput';
import Loader from 'src/components/loader';
import LocationsMismatch from 'src/components/LocationsMismatch';
import Modal from 'src/components/modal';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import { API_RESOURCES, PERMANENT_CONTAINER_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { getContainerName, pluralWord } from 'src/utils/stringUtils';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { checkSupportedMaterialsMismatch } from 'src/utils/validation';

import {
  formatUnloadQuantityInMachineNumber,
  renderRemainingValueClass,
} from '../../../material-batch/[uuid]/action/machine-unload-hopper';
import PermanentContainerActionPageWrapper from './_action-wrapper';

const ActionContainerUnload = ({ user }) => {
  const [searchParams] = useSearchParams();
  const { uuid: destinationContainerUUID } = useParams();
  const {
    printer: printerUri,
    container: sourceContainerUri,
    sieveQuantity,
    initialAction,
    containerAction,
    initialSieveAction: isInitialSieveAction,
    sieveIntoPrinter,
  } = Object.fromEntries(searchParams.entries()) ?? {};

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(null);
  const [printer, setPrinter] = useState(null);
  const [printerType, setPrinterType] = useState(null);
  const [sourceContainer, setSourceContainer] = useState(null);
  const [destinationContainer, setDestinationContainer] = useState(null);
  const [sourceContainerBatch, setSourceContainerBatch] = useState(null);
  const [destinationContainerBatch, setDestinationContainerBatch] = useState(null);
  const [subLocation, setSubLocation] = useState(undefined);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [initialQuantity, setInitialQuantity] = useState(undefined);
  const [remainingQuantity, setRemainingQuantity] = useState(undefined);
  const [quantityToUnload, setQuantityToUnload] = useState(undefined);
  const [action, setAction] = useState(initialAction);

  const isContainerSieveAction = initialAction === PERMANENT_CONTAINER_ACTIONS.SIEVE;
  const destinationResource = sieveIntoPrinter ? printer : destinationContainer;
  const areSourceAndDestinationContainersSame = (isContainerSieveAction && !printer) &&
    (sourceContainerUri === destinationContainer?.uri);

  const { units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (sourceContainer && sourceContainerBatch) {
        return convertToUserUnits(sourceContainer.quantity, sourceContainerBatch?.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [sourceContainer, sourceContainerBatch]);

  const baseSieveQuantity = parseFloat(sieveQuantity) || 0;

  const convertedRemainingQuantity = useMemo(() => {
    return isConverted
      ? convertToUserUnits(remainingQuantity, sourceContainerBatch?.units).quantity
      : remainingQuantity;
  }, [remainingQuantity, isConverted, sourceContainerBatch?.units]);

  const navigate = useNavigate();

  const unsupportedMaterials = useMemo(() => {
    return checkSupportedMaterialsMismatch(sourceContainerBatch,
      sieveIntoPrinter ? printerType : destinationContainer, false, !!sieveIntoPrinter);
  }, [sourceContainerBatch, destinationContainer, printerType]);

  const getInitialData = async () => {
    setLoading(true);
    try {

      if (!sieveIntoPrinter) {
        const destinationContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${destinationContainerUUID}/`).json();
        setDestinationContainer(destinationContainer);

        if (destinationContainer.current_batch) {
          const destinationContainerBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(destinationContainer.current_batch)}/`).json();
          setDestinationContainerBatch(destinationContainerBatch);
        }
      }

      if (sieveIntoPrinter) {
        const printer = await api.get(`${API_RESOURCES.PRINTER}/${getUuid(printerUri)}/`).json();
        setPrinter(printer);
        if (printer.printer_type) {
          const printerType = await api.get(`${API_RESOURCES.PRINTER_TYPE}/${getUuid(printer.printer_type)}/`).json();
          setPrinterType(printerType);
        }
      }

      const sourceContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${sieveIntoPrinter ? destinationContainerUUID : getUuid(sourceContainerUri)}/`).json();
      const sourceContainerBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(sourceContainer.current_batch)}/`).json();

      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(sourceContainerBatch.sub_location)}/`).json();

      const { resources: locations } = await api.get(`${API_RESOURCES.LOCATION}/`).json();
      const { resources: subLocations } = await api.get(`${API_RESOURCES.SUB_LOCATION}/`).json();

      setSourceContainerBatch(sourceContainerBatch);
      setSourceContainer(sourceContainer);
      setLocations(locations);
      setSubLocations(subLocations);

      setSubLocation(subLocation);
      setInitialQuantity(sourceContainer?.quantity);

      const currentInitialQuantity = isInitialSieveAction ? Number(sieveQuantity) : 0;
      setRemainingQuantity(sourceContainer.quantity - currentInitialQuantity);

    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const setCurrentActionHandler = () => {
    if (isContainerSieveAction) {
      return setAction(PERMANENT_CONTAINER_ACTIONS.SIEVE);
    }
    // For the future Split Action
    return setAction(PERMANENT_CONTAINER_ACTIONS.SIEVE);
  };

  useEffect(() => {
    return void getInitialData();
  }, [destinationContainerUUID]);

  useEffect(() => {
    setCurrentActionHandler();
  }, [initialAction]);

  if (!sourceContainer || !sourceContainerBatch) {
    return <Loader />;
  }

  const handleSieveBatchAction = async (quantity) => {
    const payload = {
      /* eslint-disable camelcase */
      metadata: {
        remaining_quantity: quantity,
        ...(sieveIntoPrinter ? { target_printer: printerUri } : {}),
        ...(!sieveIntoPrinter && sourceContainerUri ? { target_container: destinationContainer.uri } : {}),
      },
      action_type: PERMANENT_CONTAINER_ACTIONS.SIEVE,
      source_material_container: sourceContainer.uri,
      source_batch: sourceContainerBatch.uri,
    };

    setSubmitting(true);
    await api.post(`${API_RESOURCES.MATERIAL_CONTAINER_ACTION}/`, {
      json: payload,
    });
  };

  const onActionFinish = async (batchUri, value, action) => {
    try {
      await handleSieveBatchAction(quantityToUnload);
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(
      getRouteURI(
        routes.permanentContainerSuccess,
        { uuid: destinationContainerUUID },
        {
          action,
          batch: getUuid(sourceContainerBatch.uri),
          quantity: quantityToUnload.toFixed(2),
          remainingQuantity: value,
          subLocation: subLocation.name,
          ...(!printerUri && { sourceContainerUUID: getUuid(sourceContainer.uri) }),
          ...(printerUri && { printer: printerUri }),
          ...(containerAction && { containerAction: true }),
        }));

    return new Promise(() => {
    });
  };

  const onActionRelocate = async (permanentContainerUri, locationUri, subLocationUri) => {
    try {
      /* eslint-disable camelcase */
      const payload = {
        metadata: {
          destination_location: locationUri,
          destination_sub_location: subLocationUri,
        },
        action_type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
        source_material_container: permanentContainerUri,
        source_batch: sourceContainerBatch.uri,
      };

      setSubmitting(true);
      await api.post('material-container-action/', {
        json: payload,
      }).json();
    } catch (error_) {
      console.error(error_);
      setSubmitError(true);
      setSubmitting(false);
    }

  };

  const hideRelocationWarningModal = () => setShowRelocateModal(false);
  const hasDifferentLocations = sourceContainerBatch?.location !== destinationResource?.location || sourceContainerBatch?.sub_location !== destinationResource?.sub_location;

  const onModalConfirm = async () => {
    await onActionRelocate(sourceContainer.uri, destinationResource.location, destinationResource.sub_location);
    return onActionFinish(sourceContainerBatch.uri, remainingQuantity, action);
  };

  const isNotSupportedByMaterials = unsupportedMaterials.length;

  const renderUnsupportedMaterialsText = () => {
    const resourceTitle = sieveIntoPrinter ? 'Printer' : 'Container';
    const resourceName = sieveIntoPrinter
      ? printer?.name
      : (destinationContainer?.name || sourceContainer?.name);
    return (
      <>
        {resourceTitle} &#39;{resourceName}&#39; does not support&nbsp;
        {pluralWord('material', unsupportedMaterials)}&nbsp;
        {unsupportedMaterials.map((material) => material).join(', ')}
      </>
    );
  };

  const handleSubmitAction = () => {
    if (hasDifferentLocations) {
      setShowRelocateModal(true);
      return;
    }

    return onActionFinish(sourceContainerBatch.uri, remainingQuantity, action);
  };


  if (isLoading) {
    return <Loader />;
  }

  return (
    <PermanentContainerActionPageWrapper
      id={destinationContainerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={action}
      isLoading={isLoading}
    >
      <div className="d-flex align-items-center justify-content-center alert alert-info" role="alert">
        <b>Please record the amount of material remaining after completing the sieve action</b>
      </div>

      <p className="font-bold">Total Quantity {isContainerSieveAction ? 'to Sieve' : 'in Machine'}:&nbsp;
        <span className={renderRemainingValueClass(remainingQuantity)}>
          {formatUnloadQuantityInMachineNumber(remainingQuantity)} ({sourceContainerBatch?.units})&nbsp;
        </span>
        <span>{isConverted && formatConvertedUnits(convertedRemainingQuantity, convertedBatchUnits)}</span>
        {
          remainingQuantity < 0 && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id="sieve-value-exceeded">
                  Remaining sieve quantity exceeds the batch quantity
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} color="#FFC008" className="spacer-left" />
            </OverlayTrigger>
          )
        }
      </p>
      <hr className="flex-grow-1" />

      <ContainerQuantitySingleInput
        sourceContainer={sourceContainer}
        destinationResource={sieveIntoPrinter ? printer : destinationContainer}
        batchUnits={sourceContainerBatch?.units}
        initialQuantity={initialQuantity}
        setRemainingQuantityHandler={setRemainingQuantity}
        initialContainerQuantity={isInitialSieveAction ? baseSieveQuantity : 0}
        isNotSupportedByMaterials={isNotSupportedByMaterials}
        setSelectedQuantity={setQuantityToUnload}
      />

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={isSubmitting || (remainingQuantity === initialQuantity || remainingQuantity < 0) || isNotSupportedByMaterials}
        onClick={handleSubmitAction}
      >
        <p className="d-flex align-items-center justify-content-center mb0">
          Finish {isContainerSieveAction ? 'Sieve' : 'Unload'}
          {isSubmitting && <Loader inline className="spacer-left" showText={false} />}
          {isNotSupportedByMaterials ? (
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="workstation-is-overloaded">
                  {renderUnsupportedMaterialsText()}
                </Tooltip>)}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="d-block ml-2 warning-color" />
            </OverlayTrigger>
          ) : null}
        </p>
      </button>

      <Link to={getRouteURI(routes.printerMaterial, { uuid: getUuid(printerUri) })}>
        <button
          type="button" disabled={isSubmitting} className="btn btn-default btn-action"
        >
          Cancel
        </button>
      </Link>

      <BatchMultipleContainersAlert
        sourceBatch={sourceContainerBatch}
        destinationBatch={destinationContainerBatch}
        sourceContainer={sourceContainer}
        destinationContainer={destinationContainer}
      />

      <Modal
        isOpen={showRelocateModal}
        title="Locations Mismatch"
        dismissText="Cancel"
        confirmText="Relocate Permanent Container"
        onDismiss={hideRelocationWarningModal}
        onConfirm={onModalConfirm}
      >
        <LocationsMismatch
          sourceBatch={sourceContainerBatch}
          destinationResource={destinationResource}
          locations={locations}
          subLocations={subLocations}
          sourceType="Source Container"
          destinationType={`Destination ${sieveIntoPrinter ? 'Printer' : 'Container'}`}
          sourceDisplayName={`(${getContainerName(sourceContainer)})`}
          action="Sieve"
        />
      </Modal>

      {areSourceAndDestinationContainersSame && (
        <Alert
          name="qr-instructions"
          variant="warning"
          className="text-left d-flex align-items-center mt15"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="font-size-22 mr15" />
          <div>
            <p className="font-medium mb0">
              You have selected to sieve this material back into the source container. Are you sure this is correct?
            </p>
          </div>
        </Alert>
      )}
    </PermanentContainerActionPageWrapper>
  );
};

ActionContainerUnload.propTypes = {
  user: userPropType,
};

ActionContainerUnload.defaultProps = {
  user: null,
};

export default ActionContainerUnload;

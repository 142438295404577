import { faArchive, faCaretRight, faCoins } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ActionProgressInfo from 'src/components/action-progress-info';
import { BatchCardPreview } from 'src/components/BatchCardPreview';
import CircleProgressBar from 'src/components/circle-progress-bar';
import Loader from 'src/components/loader';
import PermanentContainerCardDetails from 'src/components/PermanentContainerCardDetails';
import PrinterCard from 'src/components/PrinterCard';
import {
  ACTION_PROGRESS_RESOURCE_NAMES,
  ACTIONS_STEPS,
  ALLOWED_ACTION_PROGRESS_ROUTES,
  BATCH_ACTIONS_TOTAL_STEPS,
} from 'src/constants/action-progress';
import useMediaQuery from 'src/hooks/useMediaQuery';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { getStepFromUrl, parseUrlForActionAndUUID } from 'src/utils/action-progress-utils';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_CONTAINER_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'src/utils/constants';
import { getShortUuid, getUuid, isRouteAllowed } from 'src/utils/url';

const ActionProgress = () => {
  const { pathname } = useLocation();
  const href = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);

  const isBatchAction = () =>
    ['batchAction', 'initialBatchAction', 'actionBatchLoad', 'actionFromBatch'].some(
      param => urlParams.get(param) === 'true'
    );

  const isPrinterAction =
    !isBatchAction() &&
    (urlParams.get('actionFromPrinter') === 'true' ||
      window.location.pathname.match(/\/printer\/([0-9a-fA-F-]{36})\//));

  const isContainerAction =
    !isBatchAction() &&
    !isPrinterAction &&
    (urlParams.get('containerAction') === 'true' ||
      window.location.pathname.match(/\/permanent-container\/([0-9a-fA-F-]{36})\//));

  const [isScrollVisible, setIsScrollVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [customNextStepName, setCustomNextStepName] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [shouldOpenPanel, setShouldOpenPanel] = useState(false);
  const [isResourceFetching, setIsResourceFetching] = useState(false);
  const [batchState, setBatchState] = useState({
    batch: null,
    allContainers: [],
    containersFetching: true,
    containersFetchError: null,
    loadedPrinter: null,
    subLocation: null,
  });

  const [permanentContainerState, setPermanentContainerState] = useState({
    batch: null,
    permanentContainer: null,
    location: null,
    subLocation: null,
  });

  const [printerState, setPrinterState] = useState({
    printer: null,
    loadedBatch: null,
    location: null,
    subLocation: null,
  });

  const resourceConditions = [
    // Highest priority first
    { condition: isContainerAction, value: API_RESOURCES.MATERIAL_CONTAINER },
    { condition: isPrinterAction, value: API_RESOURCES.PRINTER },
    // Default fallback (always matches last)
    { condition: true, value: API_RESOURCES.MATERIAL_BATCH },
  ];

  const lastScrollY = useRef(0);
  const ticking = useRef(false);

  const routeAllowed = useMemo(
    () => isRouteAllowed(pathname, ALLOWED_ACTION_PROGRESS_ROUTES),
    [pathname]
  );

  const isMobileView = useMediaQuery('(min-width: 300px) and (max-width: 600px)');

  const { uuid, action } = useMemo(() => parseUrlForActionAndUUID(href), [href]);
  const { isLoading } = useActionLoadingStore();
  const { openActionPanel, closeActionPanel } = useActionPanelStore();

  useEffect(() => {
    if (!routeAllowed || !uuid || !action || isLoading) {
      setIsVisible(false);
      return;
    }

    // Determine total steps
    const actionStepsConfig = BATCH_ACTIONS_TOTAL_STEPS[action] || { totalSteps: 1 };

    const { totalSteps } = actionStepsConfig;

    // Compute current step from the URL
    const { step: computedCurrentStep, customNextStepName } = getStepFromUrl(
      action,
      href,
      totalSteps
    );

    if (!computedCurrentStep || !totalSteps) return;

    setCurrentStep(computedCurrentStep);
    setTotalSteps(totalSteps);
    setCustomNextStepName(customNextStepName);
    setIsVisible(true);
  }, [routeAllowed, uuid, action, pathname, href, isLoading]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (currentScrollY > lastScrollY.current) {
            // Scrolling Down
            setIsScrollVisible(false);
          }
          if (currentScrollY === 0) {
            // Scrolling Up
            setIsScrollVisible(true);
          }
          lastScrollY.current = currentScrollY > 0 ? currentScrollY : 0;
          ticking.current = false;
        });

        ticking.current = true;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const matchedResource = resourceConditions.find(({ condition }) => condition);
  const resourceNameUI = ACTION_PROGRESS_RESOURCE_NAMES[matchedResource.value] ?? 'Resource';
  const isContainerResource = matchedResource.value === API_RESOURCES.MATERIAL_CONTAINER;

  // Get steps config for current action
  const actionSteps = ACTIONS_STEPS[action] || {};
  const currentStepName = actionSteps[currentStep] || 'Current Step';
  const nextStepName = customNextStepName || actionSteps[currentStep + 1] || null;
  const isLastStep = currentStep === totalSteps;

  const classes = classNames('action-progress', {
    'success-main-action-progress-bar': isLastStep,
    'action-progress-hidden': !isScrollVisible,
    'action-progress-full': !isMobileView,
    'action-progress-mobile-hidden-container':
      isMobileView && !isScrollVisible && isContainerResource,
    'action-progress-mobile-hidden': isMobileView && !isScrollVisible && !isContainerResource,
    'action-progress-container': isContainerAction,
  });

  const batchUuidClasses = classNames('action-progress-resource-uuid', {
    'action-progress-resource-uuid-hidden': !isScrollVisible,
  });

  const progressDataClasses = classNames('action-progress-data', {
    'action-progress-content-triggered': !isScrollVisible,
  });

  const nextStepClasses = classNames('action-progress-next-step', {
    'action-progress-next-step-hidden': !isScrollVisible,
  });

  const fetchMaterialBatch = async uuid => {
    try {
      setIsResourceFetching(true);

      // 1. Fetch the main batch data
      const batchData = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${uuid}/`).json();

      // Prepare the object we'll merge into our state
      let newState = {
        batch: batchData,
      };

      // 2. If batchData exists, fetch other related resources
      if (batchData) {
        const { EMPTY, ...containerStatusesExceptEmpty } = MATERIAL_CONTAINER_STATUSES;

        // Prepare parallel fetch calls
        const containerFetchPromise = api
          .get(`${API_RESOURCES.MATERIAL_CONTAINER}/`, {
            searchParams: {
              'filter[current_batch]': batchData.uri,
              'filter[status]': Object.values(containerStatusesExceptEmpty).join(','),
              'page[limit]': PAGINATION_IGNORE_DEFAULT_LIMIT,
            },
          })
          .json();

        const subLocationFetchPromise = api
          .get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batchData.sub_location)}/`)
          .json();

        // Only fetch printer data if at_machine is present
        const printerFetchPromise = batchData.at_machine
          ? api.get(`${API_RESOURCES.PRINTER}/${getUuid(batchData.at_machine)}/`).json()
          : Promise.resolve(null);

        // Wait for all requests in parallel
        const [containerFetchResult, subLocationData, loadedPrinterData] = await Promise.all([
          containerFetchPromise,
          subLocationFetchPromise,
          printerFetchPromise,
        ]);

        // Set the derived data in newState
        newState = {
          ...newState,
          allContainers: containerFetchResult.resources,
          containersFetching: false,
          subLocation: subLocationData,
        };

        if (loadedPrinterData) {
          newState.loadedPrinter = loadedPrinterData;
        }
      }

      setIsResourceFetching(false);

      // 4. Perform one state update
      setBatchState(prevState => ({
        ...prevState,
        ...newState,
      }));
    } catch (e) {
      // If any fetch fails, handle errors and end loading states
      setIsResourceFetching(false);
      setBatchState(prevState => ({
        ...prevState,
        containersFetching: false,
        containersFetchError: e,
      }));
    }
  };

  const fetchPermanentContainer = async uuid => {
    try {
      setIsResourceFetching(true);

      // 1. Fetch the main permanent container data
      const permanentContainer = await api
        .get(`${API_RESOURCES.MATERIAL_CONTAINER}/${uuid}/`)
        .json();

      // Prepare the object we'll merge into our state
      let newState = {
        permanentContainer,
      };

      // 2. Fetch the associated batch data if it exists
      const batchData = permanentContainer?.current_batch
        ? await api
            .get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`)
            .json()
        : null;

      newState = {
        ...newState,
        batch: batchData,
      };

      // Prepare parallel fetch calls
      const locationFetchPromise = api
        .get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`)
        .json();

      const subLocationFetchPromise = api
        .get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`)
        .json();

      // Wait for all requests in parallel
      const [locationData, subLocationData] = await Promise.all([
        locationFetchPromise,
        subLocationFetchPromise,
      ]);

      // Set the derived data in newState
      newState = {
        ...newState,
        location: locationData,
        subLocation: subLocationData,
      };

      setIsResourceFetching(false);

      setPermanentContainerState(prevState => ({
        ...prevState,
        ...newState,
      }));
    } catch (e) {
      // If any fetch fails, handle errors and end loading states
      setIsResourceFetching(false);
      setPermanentContainerState(prevState => ({
        ...prevState,
        containersFetching: false,
        containersFetchError: e,
      }));
    }
  };

  const fetchPrinter = async uuid => {
    try {
      setIsResourceFetching(true);

      // 1. Fetch the printer data
      const printer = await api.get(`${API_RESOURCES.PRINTER}/${uuid}/`).json();

      // Prepare the object we'll merge into our state
      let newState = {
        printer,
      };

      if (printer) {
        // 2. Fetch the associated batch data if it exists
        const { resources: batchesInPrinter } = await api
          .get(`${API_RESOURCES.MATERIAL_BATCH}/`, {
            searchParams: {
              'filter[at_machine]': printer.uri,
            },
          })
          .json();

        const isBatchLoadedToPrinter = !_isEmpty(batchesInPrinter);

        newState = {
          ...newState,
          ...(isBatchLoadedToPrinter && { loadedBatch: batchesInPrinter[0] }),
        };

        // Prepare parallel fetch calls
        const locationFetchPromise = api
          .get(`${API_RESOURCES.LOCATION}/${getUuid(printer.location)}/`)
          .json();

        const subLocationFetchPromise = api
          .get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(printer.sub_location)}/`)
          .json();

        // Wait for all requests in parallel
        const [locationData, subLocationData] = await Promise.all([
          locationFetchPromise,
          subLocationFetchPromise,
        ]);

        // Set the derived data in newState
        newState = {
          ...newState,
          location: locationData,
          subLocation: subLocationData,
        };

        setIsResourceFetching(false);

        setPrinterState(prevState => ({
          ...prevState,
          ...newState,
        }));
      }
    } catch (e) {
      // If any fetch fails, handle errors and end loading states
      setIsResourceFetching(false);
    }
  };

  const handleTriggerResourceActionPanel = async () => {
    if (isContainerAction) {
      await fetchPermanentContainer(uuid);
    } else if (isPrinterAction) {
      await fetchPrinter(uuid);
    } else {
      await fetchMaterialBatch(uuid);
    }

    setShouldOpenPanel(true);
    setIsResourceFetching(false);
  };

  useEffect(() => {
    if (shouldOpenPanel) {
      // If there's no batch data, we might want to wait or skip opening the panel
      if (!isContainerAction && !batchState.batch && !isPrinterAction && !printerState.printer) {
        return; // Or handle it differently
      }

      // Create appropriate card content
      let cardContent;
      if (isContainerAction) {
        cardContent = (
          <PermanentContainerCardDetails
            permanentContainer={permanentContainerState.permanentContainer}
            batch={permanentContainerState.batch ?? {}}
            location={permanentContainerState.location?.name}
            customQuantity={permanentContainerState.permanentContainer?.quantity || 0}
            subLocation={permanentContainerState.subLocation?.name}
            customMainStyle={{ marginTop: '0' }}
          />
        );
      } else if (isPrinterAction) {
        cardContent = (
          <PrinterCard
            canRenderWithoutBatch
            printer={printerState.printer}
            batch={printerState.loadedBatch}
            subLocation={printerState.subLocation?.name}
            customLocation={printerState.location?.name}
            customMainStyle={{ margin: '10px 25px' }}
          />
        );
      } else {
        cardContent = (
          <BatchCardPreview
            isExpanded
            shouldShowBatchLink
            batch={batchState.batch}
            allContainers={batchState.allContainers}
            containersFetching={batchState.containersFetching}
            containersFetchError={batchState.containersFetchError}
            subLocation={batchState.subLocation?.name}
            loadedPrinter={batchState.loadedPrinter}
          />
        );
      }

      openActionPanel({
        title: `View ${resourceNameUI}`,
        content: (
          <>
            <ActionProgressInfo
              currentStepName={currentStepName}
              nextStepName={nextStepName}
              currentStep={currentStep}
              totalSteps={totalSteps}
              isScrollVisible={isScrollVisible}
            />

            <div className='mb15'>{cardContent}</div>
          </>
        ),
      });

      // Reset the trigger to prevent repeated openings
      setShouldOpenPanel(false);
    }
  }, [shouldOpenPanel, isContainerAction, batchState, permanentContainerState]);

  useEffect(() => {
    // Close the action panel whenever the route changes
    closeActionPanel();
  }, [pathname]);

  if (!isVisible) return null;

  return (
    <div className={classes} style={isLastStep ? { opacity: 0 } : {}}>
      <div className={progressDataClasses} onClick={handleTriggerResourceActionPanel}>
        <div className='action-progress-icon'>
          {isResourceFetching ? (
            <Loader isMedium showText={false} className='mr3' />
          ) : (
            <FontAwesomeIcon
              className='spacer-right'
              icon={isContainerAction ? faCoins : faArchive}
            />
          )}
        </div>
        <div>
          <div className='action-progress-resource-name'>{resourceNameUI}</div>
          <div className={batchUuidClasses}>{getShortUuid(uuid)}</div>
        </div>
      </div>
      <div className='action-progress-steps position-relative'>
        {!isMobileView && (
          <div className='action-progress-step-data'>
            <div className='action-progress-current-step'>{currentStepName}</div>
            {nextStepName && (
              <div className={nextStepClasses}>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className='spacer-right action-progress-next-icon'
                />
                <span>{nextStepName}</span>
              </div>
            )}
          </div>
        )}

        <CircleProgressBar
          currentStep={currentStep}
          totalSteps={totalSteps}
          hiddenByScroll={!isScrollVisible}
        />
      </div>
    </div>
  );
};

export default ActionProgress;

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BatchMultipleContainersAlert from 'src/components/batch-multiple-containers-alert';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import routes from 'src/utils/routes';
import { formatConvertedUnits } from 'src/utils/ui';
import { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { formatDynamicPercentage, getSliderStep } from 'src/utils/validation';

import PermanentContainerActionPageWrapper from './_action-wrapper';


const SieveContainer = ({ user }) => {
  const { uuid: containerUUID } = useParams();
  const [sourceContainerData, setSourceContainerData] = useState({
    container: null,
    location: null,
    subLocation: null,
  });
  const [sourceContainerBatch, setSourceContainerBatch] = useState(null);
  const { isLoading, setLoading } = useActionLoadingStore();
  const [error, setError] = useState(undefined);

  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();

  const { quantity: convertedContainerQuantity, units: convertedBatchUnits, isConverted } =
    useMemo(() => {
      if (sourceContainerData.container && sourceContainerBatch?.units) {
        return convertToUserUnits(sourceContainerData.container.quantity, sourceContainerBatch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [sourceContainerData.container, sourceContainerBatch?.units]);


  const {
    selectedAmountBase: remainingQuantity,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  } = useSelectedAmountHandler(
    sourceContainerData.container?.quantity || 0,
    sourceContainerBatch?.units || '',
    convertedContainerQuantity,
    convertedBatchUnits
  );

  const isEmptySieveValue = remainingQuantity === undefined || remainingQuantity === '';
  const isIncorrectSieveValue = isNaN(remainingQuantity)
    || remainingQuantity > sourceContainerData.container?.quantity;

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const container = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${containerUUID}/`).json();
      if (!isEmpty(container)) {

        if (container.current_batch) {
          const sourceContainerBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(container.current_batch)}/`).json();
          setSourceContainerBatch(sourceContainerBatch);
        }

        const [containerLocation, containerSubLocation] = await Promise.all([
          api.get(`${API_RESOURCES.LOCATION}/${getUuid(container.location)}/`).then(response => response.json()),
          api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(container.sub_location)}/`).then(response => response.json()),
        ]);

        setSourceContainerData({
          container: container,
          location: containerLocation,
          subLocation: containerSubLocation,
        });

      }
    } catch (error) {
      setError(error.name);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => void fetchInitialData(), []);

  const onActionSieve = async (containerUri, remainingQuantity) => {
    const payload = {
      /* eslint-disable camelcase */
      metadata: {
        remaining_quantity: remainingQuantity,
      },
      action_type: PERMANENT_CONTAINER_ACTIONS.SIEVE,
      source_material_container: containerUri,
      source_batch: sourceContainerBatch.uri,
    };

    setSubmitting(true);
    await api.post(`${API_RESOURCES.MATERIAL_CONTAINER_ACTION}/`, {
      json: payload,
    });
  };

  const sieveWithoutContainer = async (containerUri, remainingQuantity) => {
    try {
      await onActionSieve(containerUri, remainingQuantity);
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    navigate(getRouteURI(routes.permanentContainerSuccess,
      { uuid: getUuid(containerUri) },
      {
        action: PERMANENT_CONTAINER_ACTIONS.SIEVE,
        batch: getUuid(sourceContainerBatch.uri),
      }));

    return new Promise(() => {
    });
  };

  const remainingPercentage = formatDynamicPercentage(remainingQuantity, sourceContainerData.container?.quantity);

  return (
    <PermanentContainerActionPageWrapper
      id={containerUUID}
      user={user}
      httpError={error}
      customErrorText={submitError}
      action={PERMANENT_CONTAINER_ACTIONS.SIEVE}
      isLoading={isLoading}
    >
      <div className="alert alert-info" role="alert">
        <b>
          <span
            className="mb15 d-block"
          >You are sieving container {sourceContainerData.container?.name}.
          </span>
          Please record the amount of material remaining after completing the sieve action.
        </b>
      </div>
      <div className="alert">
        <div>
          &nbsp;
          <input
            name="remainingQuantity"
            min="0"
            className="sieve-input-field"
            step={getSliderStep(convertedContainerQuantity)}
            max={convertedContainerQuantity}
            type="number"
            placeholder="Enter Remaining Quantity"
            value={selectedAmountConverted}
            onChange={handleSelectedAmountChange}
          />
          &nbsp;
          <span>{convertedBatchUnits}
            <span className={isIncorrectSieveValue ? 'text-danger' : ''}>&nbsp;({remainingPercentage || 0}%)</span>
          </span>
          {
            isIncorrectSieveValue && (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="sieve-value-exceeded">
                    Remaining sieve quantity exceeds the batch quantity
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon icon={faTriangleExclamation} color="#FFC008" className="spacer-left" />
              </OverlayTrigger>
            )
          }
        </div>
        <div className="d-flex align-items-center justify-content-center mt15">
          <div>
            <div className="mb5">0 ({sourceContainerBatch?.units})</div>
            <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
          </div>
          <div className="position-relative">
            <RangeInputConversionTooltip
              className="rangeInputAbsolute"
              visible={isConverted} defaultUnits={sourceContainerBatch?.units || ''}
              convertedUnits={convertedBatchUnits} />
            &nbsp;
            <input
              name="remainingQuantityRange"
              min="0"
              step="0.01"
              max={sourceContainerData.container?.quantity}
              type="range"
              value={remainingQuantity}
              onChange={(e) => handleRangeChange(e.target.value)}
            /> &nbsp;
          </div>
          <div>
            <div className="mb5">{sourceContainerData.container?.quantity} ({sourceContainerBatch?.units})</div>
            <div>{isConverted && formatConvertedUnits(convertedContainerQuantity, convertedBatchUnits)}</div>
          </div>
        </div>
      </div>


      <Link
        to={getRouteURI(routes.scan,
          {},
          {
            container: sourceContainerData.container?.uri,
            entity: API_RESOURCES.MATERIAL_CONTAINER,
            action: PERMANENT_CONTAINER_ACTIONS.SIEVE,
            sieveQuantity: remainingQuantity,
            initialSieveAction: true,
            containerAction: true,
          })}
        className="link-btn"
      >
        <button
          type="button"
          disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
          className="btn btn-lg btn-secondary btn-block"
        >
          Sieve into another container
        </button>
      </Link>

      <Link
        to={getRouteURI(routes.scan,
          {},
          {
            container: sourceContainerData.container?.uri,
            entity: API_RESOURCES.MATERIAL_CONTAINER,
            action: PERMANENT_CONTAINER_ACTIONS.SIEVE,
            sieveQuantity: remainingQuantity,
            initialSieveAction: true,
            containerAction: true,
            sieveIntoPrinter: true,
          })}
        className="link-btn"
      >
        <button
          type="button"
          disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
          className="btn btn-lg btn-info btn-block link-btn"
        >
          Sieve into printer
        </button>
      </Link>

      <button
        type="submit"
        className="btn btn-lg btn-light btn-block link-btn"
        disabled={isSubmitting || isIncorrectSieveValue || isEmptySieveValue}
        onClick={() => sieveWithoutContainer(sourceContainerData.container.uri, remainingQuantity)}
      >
        Sieve without container
      </button>
      <Link to={`/permanent-container/${containerUUID}`}>
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>

      <BatchMultipleContainersAlert
        sourceBatch={sourceContainerBatch}
        sourceContainer={sourceContainerData.container}
      />

    </PermanentContainerActionPageWrapper>
  );
};

SieveContainer.propTypes = {
  user: userPropType,
};

SieveContainer.defaultProps = {
  user: null,
};

export default SieveContainer;

import {
  faBoxOpen,
  faCodeBranch,
  faFillDrip,
  faFilter,
  faFlask,
  faTrashAlt,
  faVials,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionButton from 'src/components/ActionButton';
import ContainerQRCodes from 'src/components/ContainerQRCodes';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Header from 'src/components/header';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import useActionPanelStore from 'src/stores/useActionPanelStore';
import { api } from 'src/utils/api';
import {
  API_RESOURCES, MATERIAL_BATCH_ACTIONS, MATERIAL_BATCH_STATUSES,
  PERMANENT_CONTAINER_ACTIONS,
  PERMANENT_CONTAINER_STATUS_VERBOSE,
} from 'src/utils/constants';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';
import MaterialBatchCardDetails from './sections/material-batch-card-details';

const PermanentContainerPage = ({ user }) => {
  const { uuid: permanentContainerUuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [permanentContainer, setPermanentContainer] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [batch, setBatch] = useState(null);
  const isArchived = permanentContainer?.archived;

  const pageRedirects = {
    [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
        isLoad: true,
        'material-batch': permanentContainer?.current_batch,
        containerAction: true,
      }),
    [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
        containerAction: true,
      }),
    [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: getRouteURI(routes.scan,
      {},
      {
        permanentContainer: permanentContainer?.uri,
        entity: API_RESOURCES.MATERIAL_CONTAINER,
        action: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
        containerAction: true,
      }),
  };

  const navigate = useNavigate();

  const { openActionPanel } = useActionPanelStore();

  const fetchInitialData = async (permanentContainerUuid) => {
    try {
      const permanentContainer = await api.get(`${API_RESOURCES.MATERIAL_CONTAINER}/${permanentContainerUuid}/`).json();
      setPermanentContainer(permanentContainer);

      if (permanentContainer) {
        const location = await api.get(`${API_RESOURCES.LOCATION}/${getUuid(permanentContainer.location)}/`).json();
        const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(permanentContainer.sub_location)}/`).json();
        setLocationData({ location, subLocation });

        if (permanentContainer.current_batch) {
          const loadedBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`).json();
          setBatch(loadedBatch);
        }
      }
      setIsLoading(false);
      setError(null);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData(permanentContainerUuid);
  }, [permanentContainerUuid]);

  const handleShowQRCodes = () => {
    openActionPanel({
      title: 'Print QR Codes',
      content: <ContainerQRCodes
        containers={[permanentContainer]}
        backUri={getRouteURI(routes.permanentContainerDetails, { uuid: permanentContainerUuid })}
        forResource={permanentContainer.uri}
      />,
    });
  };

  if (isLoading) {
    return (
      <>
        <Header title="Loading" back="/scan" user={user} />
        <main role="main" className="text-center">
          <Loader />
        </main>
      </>
    );
  }

  if (error || !permanentContainer) {
    return (
      <>
        <Header title="Permanent Container" user={user} />
        <main role="main" className="text-center">
          <NotFound id={permanentContainerUuid} />
        </main>
      </>
    );
  }

  const isBatchDone = batch?.status === MATERIAL_BATCH_STATUSES.DONE;

  const scrapButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="scrapButton">
      <Link to={getRouteURI(routes.permanentContainerAction,
        { uuid: getUuid(permanentContainer.uri) },
        {
          type: PERMANENT_CONTAINER_ACTIONS.SCRAP,
          containerAction: true,
        }
      )}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-btn-text mm-ph-100 mm-pw-100"
          disabled={isArchived}
        >
          <FontAwesomeIcon icon={faTrashAlt} size="3x" className="d-block m-auto" />
          Scrap
        </button>
      </Link>
    </div>
  );

  const sieveButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="sieveButton">
      <Link
        to={getRouteURI(routes.permanentContainerActionSieve,
          { uuid: getUuid(permanentContainer.uri) },
          { containerAction: true })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={isArchived}
        >
          <FontAwesomeIcon icon={faFilter} size="3x" className="d-block m-auto" />
          Sieve
        </button>
      </Link>
    </div>
  );

  const relocateButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="relocateButton">
      <Link
        to={getRouteURI(routes.permanentContainerAction,
          { uuid: getUuid(permanentContainer.uri) },
          { type: PERMANENT_CONTAINER_ACTIONS.RELOCATE, containerAction: true })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
          disabled={isArchived}
        >
          <FontAwesomeIcon icon={faBoxOpen} size="3x" className="d-block m-auto" />
          Relocate
        </button>
      </Link>
    </div>
  );

  const treatmentButton = (
    <div className="batch-option-button relocate-warning-wrapper" id="treatmentButton">
      <Link
        to={getRouteURI(routes.permanentContainerAction,
          { uuid: getUuid(permanentContainer.uri) },
          { type: PERMANENT_CONTAINER_ACTIONS.TREATMENT, containerAction: true })}
      >
        <button
          disabled
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100 mm-btn-text"
        >
          <FontAwesomeIcon icon={faFlask} size="3x" className="d-block m-auto" />
          Treatment
        </button>
      </Link>
    </div>
  );

  const testButton = (
    <div className="batch-option-button" id="testButton">
      <Link
        to={getRouteURI(routes.materialBatchAction, { uuid: getUuid(permanentContainer.current_batch) }, {
          type: MATERIAL_BATCH_ACTIONS.TEST,
          containerUUID: getUuid(permanentContainer.uri),
          containerAction: true,
        })}
      >
        <button
          type="button"
          className="btn btn-outline-secondary btn-action mm-ph-100 mm-pw-100"
          disabled={isArchived}
        >
          <FontAwesomeIcon icon={faVials} size="3x" className="d-block m-auto" />
          Test
        </button>
      </Link>
    </div>
  );

  const renderActionButton = (type, redirectLink) => {
    const BUTTON_TYPES = {
      [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: {
        button: (
          <ActionButton
            disabled={isArchived}
            className="btn-primary"
            id="loadButton"
            onClick={() => navigate(pageRedirects[type])}
          >
            <FontAwesomeIcon icon={faFillDrip} size="3x" className="d-block m-auto" />
            Load
          </ActionButton>
        ),
      },
      [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: {
        button: (
          <ActionButton
            skipDefaultClassName
            className="btn-outline-secondary"
            id="blendButton"
            disabled={isArchived}
            onClick={() => navigate(pageRedirects[type])}
          >
            <FontAwesomeIcon icon={faFillDrip} size="3x" className="d-block m-auto" />
            Top Off
          </ActionButton>
        ),
      },
      [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: {
        button: (
          <ActionButton
            skipDefaultClassName
            className="btn-outline-secondary"
            id="splitButton"
            disabled={isArchived}
            onClick={() => navigate(pageRedirects[type])}
          >
            <FontAwesomeIcon icon={faCodeBranch} size="3x" className="d-block m-auto" />
            Split
          </ActionButton>
        ),
      },
    };

    return (
      <div
        className="batch-option-button relocate-warning-wrapper"
        id={type === PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL ? 'loadButton' : 'blendButton'}
      >
        <Link
          to={redirectLink}
        >
          {BUTTON_TYPES[type].button}
        </Link>
      </div>
    );
  };


  return (
    <>
      <Header title="Permanent Container" user={user} onPrintQRCodesClick={handleShowQRCodes} />
      <main role="main" className="text-center">
        <div>
          <h2 className="header-margin">
            {permanentContainer.name}
            <div className="container-title-uuid font-size-18">({getShortUuid(permanentContainer.uuid)})</div>
          </h2>
          <h3 className="header-margin">
            Location:
            <div className="mt10">
              <span className="badge badge-secondary">{locationData.location.name}</span> /&nbsp;
              <span className="badge badge-secondary">{locationData.subLocation.name}</span>
            </div>
          </h3>
          {batch && (
            <h3 className="header-margin">Quantity:&nbsp;
              <ConvertedUnits quantity={permanentContainer.quantity} units={batch.units} />
            </h3>
          )}

          {!permanentContainer.current_batch && (
            <h4 className="header-margin-large">
              Current Status:&nbsp;
              <span className="badge badge-warning">
                {isArchived ? 'Archived' : PERMANENT_CONTAINER_STATUS_VERBOSE[permanentContainer.status]}
              </span>
            </h4>
          )}

          <MaterialBatchCardDetails batch={batch} />

          {permanentContainer.current_batch && (
            <Link className="link-btn" to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}>
              <button type="button" className="btn btn-lg btn-secondary btn-block mb15">View Batch Actions</button>
            </Link>
          )}

          {permanentContainer.current_batch && !isBatchDone && (
            <div className="batch-options-button-wrapper">
              {renderActionButton(
                PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
                pageRedirects[PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER])}
              {scrapButton}
              {treatmentButton}
              {testButton}
              {renderActionButton(
                PERMANENT_CONTAINER_ACTIONS.TOP_OFF,
                pageRedirects[PERMANENT_CONTAINER_ACTIONS.TOP_OFF])}
              {sieveButton}
              {relocateButton}
              {renderActionButton(
                PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                pageRedirects[PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL])}
            </div>
          )}


          {(!permanentContainer.current_batch || isBatchDone) && (
            <>
              <Link
                to={getRouteURI(routes.scan,
                  {},
                  {
                    permanentContainer: permanentContainer.uri,
                    entity: API_RESOURCES.MATERIAL_CONTAINER,
                    action: PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
                    containerAction: true,
                    isFill: true,
                  })}
                className="link-btn"
              >
                <button type="button" disabled={isArchived} className="btn btn-lg btn-primary btn-block">
                  <FontAwesomeIcon icon={faFillDrip} className="spacer-right" />
                  Fill
                </button>
              </Link>
              <Link
                to={getRouteURI(routes.permanentContainerAction,
                  { uuid: permanentContainerUuid },
                  {
                    type: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
                    containerAction: true,
                  })}
                className="link-btn"
              >
                <button
                  type="button"
                  disabled={isArchived}
                  className="btn btn-lg btn-primary btn-block"
                >
                  <FontAwesomeIcon icon={faBoxOpen} className="spacer-right" />
                  Relocate
                </button>
              </Link>
            </>
          )}

        </div>
      </main>

    </>
  );
};

PermanentContainerPage.propTypes = {
  error: PropTypes.shape({}),
  user: userPropType,
};

PermanentContainerPage.defaultProps = {
  error: null,
  user: null,
};

export default PermanentContainerPage;

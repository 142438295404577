export const API_RESOURCES = {
  DOCUMENT: 'document',
  FEATURE: 'feature',
  PRINTER: 'printer',
  PRINTER_TYPE: 'printer-type',
  POST_PROCESSOR: 'post-processor',
  MATERIAL: 'material',
  MATERIAL_CONTAINER: 'material-container',
  MATERIAL_BATCH: 'material-batch',
  MATERIAL_LOT: 'material-lot',
  MATERIAL_BATCH_ACTION: 'material-batch-action',
  MATERIAL_CONTAINER_ACTION: 'material-container-action',
  RUN: 'run',
  RUN_ACTUALS: 'run-actuals',
  RUN_ACTUALS_ADDITIVE: 'run-actuals-additive',
  PRINT: 'print',
  PIECE: 'piece',
  LINE_ITEM: 'line-item',
  MODEL: 'model',
  EXTERNAL_BUILDS: 'external-builds',
  ORDER: 'order',
  SCHEDULE_RUNS: 'schedule_runs',
  TIME_ENTRY: 'time-entry',
  TOOLING_STOCK: 'tooling-stock',
  TOOL_LINK: 'tool-link',
  TOOLING_STOCK_ACTION: 'tooling-stock-action',
  TOOLING_TYPE: 'tooling-type',
  WORK_INSTRUCTION: 'work-instruction',
  WORK_INSTRUCTION_REPORT: 'work-instruction-report',
  WORK_INSTRUCTION_REPORT_ACTION: 'work-instruction-report-action',
  WORK_CHECKLIST_FOR_RESOURCE: 'work-checklists-for-resource',
  WORKFLOW: 'workflow',
  PUBLIC_GROUP: 'public-group',
  SHIPMENT: 'shipment',
  SHIPPING: 'shipping',
  // Permissions system check data
  // (based on Public Group, but with extended bureau permissions check, see BE implementation for more details)
  ACCESS_INFO_FOR_RESOURCE: 'access-info-for-resource',
  RUN_TRANSFORMATION: 'run-transformation',
  PROCESS_STEP: 'process-step',
  COMMENT: 'comment',
  USERS: 'users',
  SAMPLE: 'sample',
  SUMMARY: 'summary',
  LOCATION: 'location',
  SUB_LOCATION: 'sub-location',
  LABEL: 'label',
  LABEL_RELATIONSHIP: 'label-relationship',
  BUREAU_SETTINGS: 'bureau-settings',
};

export const API_RESOURCES_MAP = {
  [API_RESOURCES.ACCESS_INFO_FOR_RESOURCE]: 'access info for resource',
  [API_RESOURCES.DOCUMENT]: 'document',
  [API_RESOURCES.FEATURE]: 'feature',
  [API_RESOURCES.PRINTER]: 'machine',
  [API_RESOURCES.PRINTER_TYPE]: 'printer type',
  [API_RESOURCES.POST_PROCESSOR]: 'machine',
  [API_RESOURCES.MATERIAL]: 'material',
  [API_RESOURCES.MATERIAL_CONTAINER]: 'container',
  [API_RESOURCES.MATERIAL_BATCH]: 'material batch',
  [API_RESOURCES.MATERIAL_LOT]: 'material lot',
  [API_RESOURCES.MATERIAL_BATCH_ACTION]: 'material batch action',
  [API_RESOURCES.MATERIAL_CONTAINER_ACTION]: 'material container action',
  [API_RESOURCES.RUN]: 'run',
  [API_RESOURCES.RUN_ACTUALS]: 'run actuals',
  [API_RESOURCES.RUN_ACTUALS_ADDITIVE]: 'run actuals additive',
  [API_RESOURCES.PRINT]: 'print',
  [API_RESOURCES.PIECE]: 'piece',
  [API_RESOURCES.LINE_ITEM]: 'line item',
  [API_RESOURCES.MODEL]: 'model',
  [API_RESOURCES.EXTERNAL_BUILDS]: 'External Build ID',
  [API_RESOURCES.ORDER]: 'order',
  [API_RESOURCES.PUBLIC_GROUP]: 'public group',
  [API_RESOURCES.SCHEDULE_RUNS]: 'scheduled runs',
  [API_RESOURCES.TIME_ENTRY]: 'time entry',
  [API_RESOURCES.TOOLING_STOCK]: 'tool',
  [API_RESOURCES.TOOL_LINK]: 'tool link',
  [API_RESOURCES.TOOLING_STOCK_ACTION]: 'tool action',
  [API_RESOURCES.TOOLING_TYPE]: 'tool type',
  [API_RESOURCES.WORK_INSTRUCTION]: 'work instruction',
  [API_RESOURCES.WORK_INSTRUCTION_REPORT]: 'work instruction report',
  [API_RESOURCES.WORK_INSTRUCTION_REPORT_ACTION]: 'work instruction report action',
  [API_RESOURCES.WORK_CHECKLIST_FOR_RESOURCE]: 'work checklists for resource',
  [API_RESOURCES.WORKFLOW]: 'workflow',
  [API_RESOURCES.SHIPMENT]: 'shipment',
  [API_RESOURCES.PROCESS_STEP]: 'process step',
  [API_RESOURCES.COMMENT]: 'comments',
  [API_RESOURCES.USERS]: 'users',
  [API_RESOURCES.RUN_TRANSFORMATION]: 'run-transformation',
  [API_RESOURCES.SAMPLE]: 'sample',
  [API_RESOURCES.SUMMARY]: 'summary',
  [API_RESOURCES.SUB_LOCATION]: 'sub-location',
  [API_RESOURCES.SHIPPING]: 'shipping',
  [API_RESOURCES.LOCATION]: 'location',
  [API_RESOURCES.LABEL]: 'label',
  [API_RESOURCES.LABEL_RELATIONSHIP]: 'label relationship',
  [API_RESOURCES.BUREAU_SETTINGS]: 'bureau-settings',
};

export const ACCESS_INFO_ACTION_TYPES = {
  EDIT: 'edit', // PUT request
  DELETE: 'delete', // DELETE request
  USE: 'use', // using an entity URI in other entity field. E.g. `process_step.workstation_type`, etc.
  // Edge-case actions
  REDO: 'redo', // `redo` action is a Run transformation on some/all of Run.Prints provided for a Run resource
  REMANUFACTURE: 'remanufacture', // `remanufacture` is a Run transformation action, provided on a piece resource
  CHANGE_WORKFLOW: 'change_workflow', // `change workflow` is a Run transformation action, provided on a piece resource
};

export const RUN_STATUS = {
  created: 'created',
  calculating: 'calculating',
  calculated: 'calculated',
  queued: 'queued',
  queuedReady: 'queued-work-ready',
  inProgress: 'in-progress',
  paused: 'paused',
  complete: 'complete',
  error: 'error',
  cancelled: 'cancelled',
};

// In backend implementation it's like the same statuses, as in run,
// but also with `created` and `incomplete` statuses
export const PRINT_STATUSES = Object.assign({}, RUN_STATUS, {
  CREATED: 'created',
  INCOMPLETE: 'incomplete',
});

export const PRINT_ERROR_STATUSES = [
  PRINT_STATUSES.error,
  PRINT_STATUSES.cancelled,
  PRINT_STATUSES.INCOMPLETE,
];

export const RUN_STATUS_ORDER = [
  RUN_STATUS.created,
  RUN_STATUS.calculating,
  RUN_STATUS.calculated,
  RUN_STATUS.queued,
  RUN_STATUS.queuedReady,
  RUN_STATUS.inProgress,
  RUN_STATUS.paused,
  RUN_STATUS.complete,
  RUN_STATUS.error,
  RUN_STATUS.cancelled,
];

export const RUN_STATUS_LABELS = {
  [RUN_STATUS.created]: 'Created',
  [RUN_STATUS.calculating]: 'Calculating',
  [RUN_STATUS.calculated]: 'Calculated',
  [RUN_STATUS.queued]: 'Queued',
  [RUN_STATUS.queuedReady]: 'Queued Work Ready',
  [RUN_STATUS.inProgress]: 'In Progress',
  [RUN_STATUS.paused]: 'Paused',
  [RUN_STATUS.complete]: 'Completed',
  [RUN_STATUS.error]: 'Failed',
  [RUN_STATUS.cancelled]: 'Cancelled',
};

export const RUN_OPERATIONS = {
  PRINTING: 'printing',
  POST_PROCESSING: 'post-processing',
  SHIPPING: 'shipping',
};

export const SHIPMENT_STATUS = {
  new: 'new',
  queued: 'queued',
  inTransit: 'in_transit',
  received: 'received',
  error: 'error',
  cancelled: 'cancelled',
};

export const SHIPMENT_STATUS_LABELS = {
  [SHIPMENT_STATUS.new]: 'New',
  [SHIPMENT_STATUS.queued]: 'Queued',
  [SHIPMENT_STATUS.inTransit]: 'In Transit',
  [SHIPMENT_STATUS.received]: 'Received',
  [SHIPMENT_STATUS.error]: 'Error',
  [SHIPMENT_STATUS.cancelled]: 'Cancelled',
};

export const NCR_FLAG = 'flag_non_conformance';

export const PIECE_STATUS = {
  NEW: 'new',
  CONFIRMED: 'confirmed',
  QUEUED_FOR_PRINTING: 'queued-for-printing',
  PRINTING: 'printing',
  QUEUED_FOR_POST_PROCESSING: 'queued-for-post-processing',
  POST_PROCESSING: 'post-processing',
  QUEUED_FOR_SHIPPING: 'queued-for-shipping',
  SHIPPING: 'shipping',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled',
  ERROR: 'error',
};

export const COLORS = {
  PURPLE: '#9F86FF',
  BLUE: '#1CA8DD',
  YELLOW: '#E4D836',
  ORANGE: '#FFA500',
  GRAY: '#555555',
  GREEN: '#1BC98E',
  RED: '#E64759',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
};

export const PIECE_STATUS_BADGE_COLORS = {
  [PIECE_STATUS.NEW]: '#a799ff',
  [PIECE_STATUS.CONFIRMED]: '#04a5e2',
  [PIECE_STATUS.QUEUED_FOR_PRINTING]: '#04a5e2',
  [PIECE_STATUS.PRINTING]: '#ffac00',
  [PIECE_STATUS.QUEUED_FOR_POST_PROCESSING]: '#04a5e2',
  [PIECE_STATUS.POST_PROCESSING]: '#ffac00',
  [PIECE_STATUS.QUEUED_FOR_SHIPPING]: '#04a5e2',
  [PIECE_STATUS.SHIPPING]: '#5f5f61',
  [PIECE_STATUS.COMPLETE]: '#0abb99',
  [PIECE_STATUS.INCOMPLETE]: '#5f5f61',
  [PIECE_STATUS.CANCELLED]: '#5f5f61',
  [PIECE_STATUS.ERROR]: '#f26264',
};

export const PIECE_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  // Legacy, not used anywhere
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const WORK_INSTRUCTION_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  NO_ENTRY: 'no entry',
  RANGE: 'range',
  DOCUMENT: 'document',
  PICTURE: 'picture',
  SINGLE_SELECT_DROPDOWN: 'single-selection-dropdown',
  OUTPUT_AT_LOCATION: 'output-at-location',
};

export const WORK_INSTRUCTION_DOCUMENT_TYPES = [
  WORK_INSTRUCTION_TYPES.DOCUMENT,
  WORK_INSTRUCTION_TYPES.PICTURE,
];

export const MATERIAL_BATCH_ACTIONS = {
  CREATE_INITIAL_BATCH: 'create_initial_batch',
  MACHINE_LOAD: 'machine_load',
  BLEND_BATCHES: 'blend_batches',
  MACHINE_UNLOAD_CHAMBER: 'machine_unload_chamber',
  UNLOAD_RECLAIMED_MATERIAL: 'unload_reclaimed_material',
  SPLIT_BATCH: 'split_batch',
  ADD_CONTAINER: 'add_container',
  CONTAINERIZE_BATCH: 'containerize_batch',
  MACHINE_UNLOAD_HOPPER: 'machine_unload_hopper',
  SCRAP_BATCH: 'scrap_batch',
  SIEVE: 'sieve',
  TEST: 'test',
  START_TEST: 'start_test',
  MACHINE_TOP_OFF: 'machine_top_off',
  RELOCATE: 'relocate',
  TREATMENT: 'treatment',
};

export const RUN_ACTIONS = {
  SCAN_TOOLING_STOCK: 'scan_tooling_stock',
};

export const PRINTER_ACTIONS = {
  SCAN_EXTERNAL_BUILD_ID: 'scan_external_build_ud',
  LOAD_MATERIAL: 'load_material',
};

export const PERMANENT_CONTAINER_ACTIONS = {
  LOAD_MATERIAL: 'load_container',
  LOAD_MATERIAL_BATCH: 'load_material_batch',
  TOP_OFF: 'top_off_container',
  TRANSFER_MATERIAL: 'transfer_container',
  SPLIT_CONTAINER: 'split_container',
  MACHINE_LOAD: 'machine_load',
  BLEND_CONTAINERS: 'blend_containers',
  SCRAP: 'scrap_container',
  UNLOAD_UNUSED_MATERIAL: 'unload_unused_material',
  UNLOAD_RECLAIMED_MATERIAL: 'unload_reclaimed_material',
  RELOCATE: 'relocate_container',
  SIEVE_BATCH: 'sieve_batch',
  UNLOAD_TO_CONTAINER: 'action_unload_to_container',
  CONTAINER_UNLOAD: 'action_container_unload',
  MATERIAL_LOAD: 'load_material',
  SIEVE: 'sieve',
  TREATMENT: 'treatment',
  TEST: 'test',
};

export const MATERIAL_BATCH_ACTIONS_VERBOSE = {
  [MATERIAL_BATCH_ACTIONS.CREATE_INITIAL_BATCH]: 'Initial Batch Process',
  [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: 'Machine Load',
  [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: 'Blend Batches',
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: 'Machine Unload Chamber',
  [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: 'Split Batch',
  [MATERIAL_BATCH_ACTIONS.ADD_CONTAINER]: 'Add Container',
  [MATERIAL_BATCH_ACTIONS.CONTAINERIZE_BATCH]: 'Containerize Batch',
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: 'Machine Unload Hopper',
  [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: 'Scrap Batch',
  [MATERIAL_BATCH_ACTIONS.SIEVE]: 'Sieve',
  [MATERIAL_BATCH_ACTIONS.RELOCATE]: 'Relocate',
  [MATERIAL_BATCH_ACTIONS.TREATMENT]: 'Treatment',
  [MATERIAL_BATCH_ACTIONS.TEST]: 'Test',
  [MATERIAL_BATCH_ACTIONS.START_TEST]: 'Start Test',
  [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: 'Top Off',
  [MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: 'Unload Reclaimed Material',
};

export const RUN_ACTIONS_VERBOSE = {
  [RUN_ACTIONS.SCAN_TOOLING_STOCK]: 'Scan Tool',
};

export const PERMANENT_CONTAINER_ACTIONS_VERBOSE = {
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL]: 'Fill Container',
  [PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL_BATCH]: 'Fill Container',
  [PERMANENT_CONTAINER_ACTIONS.TOP_OFF]: 'Top Off',
  [PERMANENT_CONTAINER_ACTIONS.TRANSFER_MATERIAL]: 'Transfer Material',
  [PERMANENT_CONTAINER_ACTIONS.RELOCATE]: 'Relocate',
  [PERMANENT_CONTAINER_ACTIONS.SCRAP]: 'Scrap Container',
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_UNUSED_MATERIAL]: 'Unload Material',
  [PERMANENT_CONTAINER_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: 'Unload Reclaimed Material',
  [PERMANENT_CONTAINER_ACTIONS.SIEVE_BATCH]: 'Sieve Batch',
  [PERMANENT_CONTAINER_ACTIONS.SIEVE]: 'Sieve Container',
  [PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER]: 'Split Container',
};

export const MATERIAL_BATCH_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  IDLE: 'idle',
  DONE: 'done',
  CONSUMED: 'consumed',
};

export const MATERIAL_BATCH_POWDER_QUALITY_STATUSES = {
  NEW: 'new',
  SIEVED: 'sieved',
  DONE: 'done',
  UNKNOWN: 'unknown',
  UNSIEVED: 'unsieved',
};

export const PERMANENT_CONTAINER_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  EMPTY: 'empty',
};

export const TOOLING_STOCK_STATUSES = {
  NEW: 'new',
  IN_USE: 'in-use',
  IDLE: 'idle',
  NEEDS_REFURBISHMENT: 'needs-refurbishment',
  DOING_REFURBISHMENT: 'doing-refurbishment',
  REFURBISHED: 'refurbished',
  ARCHIVED: 'archived',
};

export const MATERIAL_BATCH_WARNING_POWDER_QUALITY_STATUSES = [
  MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNKNOWN,
  MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNSIEVED,
];

export const MATERIAL_CONTAINER_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  EMPTY: 'empty',
};

export const MATERIAL_BATCH_CUSTOM_STATUSES = {
  IN_TESTING: 'in_testing',
  TESTING_COMPLETE: 'testing_complete',
};

export const MATERIAL_BATCH_STATUS_VERBOSE = {
  [MATERIAL_BATCH_STATUSES.NEW]: 'New',
  [MATERIAL_BATCH_STATUSES.IN_USE]: 'In Use',
  [MATERIAL_BATCH_STATUSES.IDLE]: 'Idle',
  [MATERIAL_BATCH_STATUSES.DONE]: 'Done',
  [MATERIAL_BATCH_STATUSES.CONSUMED]: 'Consumed',
};
export const TOOLING_STOCK_STATUS_VERBOSE = {
  [TOOLING_STOCK_STATUSES.NEW]: 'New',
  [TOOLING_STOCK_STATUSES.IN_USE]: 'In Use',
  [TOOLING_STOCK_STATUSES.IDLE]: 'Ready For Use',
  [TOOLING_STOCK_STATUSES.NEEDS_REFURBISHMENT]: 'Needs Refurbishment',
  [TOOLING_STOCK_STATUSES.DOING_REFURBISHMENT]: 'Doing Refurbishment',
  [TOOLING_STOCK_STATUSES.REFURBISHED]: 'Refurbished',
  [TOOLING_STOCK_STATUSES.ARCHIVED]: 'Archived',
};

export const PERMANENT_CONTAINER_STATUS_VERBOSE = {
  [PERMANENT_CONTAINER_STATUSES.NEW]: 'New',
  [PERMANENT_CONTAINER_STATUSES.IN_USE]: 'In Use',
  [PERMANENT_CONTAINER_STATUSES.EMPTY]: 'Empty',
};

export const WORK_INSTRUCTION_FILE_TYPES = [
  WORK_INSTRUCTION_TYPES.PICTURE,
  WORK_INSTRUCTION_TYPES.DOCUMENT,
];

export const LOCALSTORAGE_KEYS = {
  DEBUG_MODE: 'debugMode',
  AUTOLOGOUT_AFTER: 'autologoutAfter',
  SCAN_MODE: 'scanMode',
  UNITS_SETTINGS: 'unitsSettings',
  ACTION_SCANNED_CONTAINERS: 'actionScannedContainers',
};

export const REFERENCE_TABLE_NAMES = {
  WORK_INSTRUCTION_REPORT: 'work_instruction_report',
};

export const TIME_ENTRY_RELATED_TABLE_NAME = {
  RUN: 'run',
  PREP_TASK_RECORD: 'prep_task_record',
};

export const TIME_ENTRY_CATEGORIES = {
  ATTENDING: 'attending',
  MACHINE_RUNNING: 'machine-running',
  // Preparation workflow feature (is not used in QR):
  IN_PROGRESS: 'in-progress',
  ON_HOLD: 'on-hold',
};

export const PRINT_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  // For future use
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const WORK_INSTRUCTION_UNITS = {
  MM: 'mm',
  CM: 'cm',
  INCH: 'inch',
  GRAM: 'gram',
  KG: 'kg',
  LB: 'lb',
};

export const WORK_INSTRUCTION_REPORT_UNITS_VERBOSE = {
  [WORK_INSTRUCTION_UNITS.MM]: 'mm',
  [WORK_INSTRUCTION_UNITS.CM]: 'cm',
  [WORK_INSTRUCTION_UNITS.INCH]: 'in',
  [WORK_INSTRUCTION_UNITS.GRAM]: 'g',
  [WORK_INSTRUCTION_UNITS.KG]: 'kg',
  [WORK_INSTRUCTION_UNITS.LB]: 'lb',
};

export const MODEL_UNITS_VERBOSE = {
  [WORK_INSTRUCTION_UNITS.MM]: 'Millimeters',
  [WORK_INSTRUCTION_UNITS.CM]: 'Centimeters',
  [WORK_INSTRUCTION_UNITS.INCH]: 'Inches',
};

export const WORKFLOW_TYPES = {
  SPECIMEN: 'specimen',
  ADDITIVE_MANUFACTURING: 'additive_manufacturing',
  POWDER_MANUFACTURING: 'powder_manufacturing',
};

export const ALLOWED_DOCUMENT_UPLOAD_FORMATS = [
  'doc',
  'docx',
  'pdf',
  'stl',
  'mp3',
  'dxf',
  'csv',
  'xls',
  'xlsx',
  'jpeg',
  'png',
  'obj',
  'stp',
  'step',
  '3mf',
  'dae',
  'ply',
  'igs',
  'brep',
  'catpart',
  'cmb',
];

// TODO This variable is created to track points where do we need to fetch
//  all non-paginated objects.
//  Must be removed after implementing algorithm for disabling pagination
export const PAGINATION_IGNORE_DEFAULT_LIMIT = 5000;

export const CONTACTLESS_LOGON_TIMEOUT = 3600 * 1000;
export const LOGON_WARNING_TIMEOUT = 30 * 1000;

export const CONTACTLESS_LOGIN_ENABLED = 'contactlessLoginEnabled';

export const BUREAU_BARCODE_FORMAT = {
  QR: 'qr',
  BARCODE: 'barcode',
};

export const SCAN_BUTTON_STATES = {
  initialized: 'initialized',
  loading: 'loading',
  success: 'success',
};

export const IDENTITY_PROVIDER_TYPE = {
  SAML: 'SAML',
  LDAPS: 'LDAPS',
};

export const BARCODE_SCANNER_STATES = {
  ready: 'ready',
  inactive: 'inactive',
  idle: 'idle',
};

export const SHORT_UUID_LENGTH = 8;
export const FULL_UUID_LENGTH = 36;

export const BATCH_STATUS_COLOR_MAP = {
  [MATERIAL_BATCH_STATUSES.NEW]: COLORS.PURPLE,
  [MATERIAL_BATCH_STATUSES.IN_USE]: COLORS.ORANGE,
  [MATERIAL_BATCH_STATUSES.DONE]: COLORS.GREEN,
  [MATERIAL_BATCH_STATUSES.CONSUMED]: COLORS.GRAY,
  [MATERIAL_BATCH_STATUSES.IDLE]: COLORS.ORANGE,
};

export const PERMANENT_CONTAINER_STATUS_COLOR_MAP = {
  [PERMANENT_CONTAINER_STATUSES.NEW]: COLORS.PURPLE,
  [PERMANENT_CONTAINER_STATUSES.IN_USE]: COLORS.ORANGE,
  [PERMANENT_CONTAINER_STATUSES.EMPTY]: COLORS.GRAY,
};

export const TOOLING_STOCK_STATUS_COLOR_MAP = {
  [TOOLING_STOCK_STATUSES.NEW]: COLORS.PURPLE,
  [TOOLING_STOCK_STATUSES.IDLE]: COLORS.GREEN,
  [TOOLING_STOCK_STATUSES.IN_USE]: COLORS.ORANGE,
  [TOOLING_STOCK_STATUSES.NEEDS_REFURBISHMENT]: COLORS.YELLOW,
  [TOOLING_STOCK_STATUSES.DOING_REFURBISHMENT]: COLORS.ORANGE,
  [TOOLING_STOCK_STATUSES.REFURBISHED]: COLORS.GREEN,
  [TOOLING_STOCK_STATUSES.ARCHIVED]: COLORS.GRAY,
};

export const RUN_STATUS_COLOR_MAP = {
  [RUN_STATUS.calculating]: COLORS.YELLOW,
  [RUN_STATUS.calculated]: COLORS.PURPLE,
  [RUN_STATUS.queued]: COLORS.BLUE,
  [RUN_STATUS.inProgress]: COLORS.ORANGE,
  [RUN_STATUS.paused]: COLORS.GRAY,
  [RUN_STATUS.complete]: COLORS.GREEN,
  [RUN_STATUS.error]: COLORS.RED,
  [RUN_STATUS.cancelled]: COLORS.GRAY,
};

export const STATUS_COLOR_CODE_TYPES = {
  BATCH_STATUS: 'batch_status',
  PERMANENT_CONTAINER_STATUS: 'permanent_container_status',
  TOOLING_STOCK_STATUS: 'tooling_stock_status',
  RUN_STATUS: 'run_status',
};

export const STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP = {
  [STATUS_COLOR_CODE_TYPES.BATCH_STATUS]: BATCH_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.PERMANENT_CONTAINER_STATUS]: PERMANENT_CONTAINER_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.TOOLING_STOCK_STATUS]: TOOLING_STOCK_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.RUN_STATUS]: RUN_STATUS_COLOR_MAP,
};

export const JSON_ERRORS = {
  INVALID_CREDENTIALS: 'invalid-credentials',
};

export const EXPORT_CONTROL_LABEL_NAMES = {
  UK_THIRD_PARTY: 'UK Third Party',
  US_THIRD_PARTY: 'US Third Party',
  NO_EXPORT_CONTROL: 'No Export Control',
};

export const TOOLING_STOCK_ACTION_TYPES = {
  RELOCATE: 'relocate',
  RECORD_TESTS: 'record_tests',
  MAKE_READY_FOR_USE: 'make_ready_for_use',
  PUT_INTO_USE: 'put_into_use',
  COMPLETE_USE: 'complete_use',
  MARK_NEEDS_REFURBISH: 'mark_needs_refurbish',
  SEND_TO_REFURBISH: 'send_to_refurbish',
  COMPLETE_REFURBISH: 'complete_refurbish',
  DISCARD: 'discard',
};

export const TOOLING_STOCK_ACTION_VERBOSE = {
  [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: 'Relocate',
  [TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS]: 'Record Tests',
  [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: 'Make Ready For Use',
  [TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE]: 'Put Into Use',
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE]: 'Complete Use',
  [TOOLING_STOCK_ACTION_TYPES.MARK_NEEDS_REFURBISH]: 'Mark Needs Refurbish',
  [TOOLING_STOCK_ACTION_TYPES.SEND_TO_REFURBISH]: 'Send To Refurbish',
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH]: 'Complete Refurbish',
  [TOOLING_STOCK_ACTION_TYPES.DISCARD]: 'Discard',
};

export const TOOLING_STOCK_ACTION_SUCCESS_MESSAGES = {
  [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: 'The tool has been successfully relocated.',
  [TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS]: 'The tests have been successfully recorded.',
  [TOOLING_STOCK_ACTION_TYPES.SEND_TO_REFURBISH]:
    'The tool has been successfully sent to refurbish.',
  [TOOLING_STOCK_ACTION_TYPES.DISCARD]: 'The tool has been successfully discarded.',
  [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: 'The tool is now ready for use.',
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH]: 'The tool has been successfully refurbished.',
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE]: 'The tool has been successfully used.',
  [TOOLING_STOCK_ACTION_TYPES.MARK_NEEDS_REFURBISH]: 'The tool has been marked for refurbishment.',
};

const TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS = [
  TOOLING_STOCK_ACTION_TYPES.RELOCATE,
  TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS,
  TOOLING_STOCK_ACTION_TYPES.DISCARD,
  TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE,
];

export const TOOLING_STOCK_ALLOWED_ACTIONS = {
  [TOOLING_STOCK_STATUSES.NEW]: [
    TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.IDLE]: [
    TOOLING_STOCK_ACTION_TYPES.MARK_NEEDS_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.IN_USE]: [
    TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.NEEDS_REFURBISHMENT]: [
    TOOLING_STOCK_ACTION_TYPES.SEND_TO_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.DOING_REFURBISHMENT]: [
    TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.REFURBISHED]: [
    TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE,
    ...TOOLING_STOCK_ALWAYS_ALLOWED_ACTIONS,
  ],
  [TOOLING_STOCK_STATUSES.ARCHIVED]: [],
};

export const BATCH_CARD_TABS = {
  LOTS: 'lots',
  CONTAINERS: 'containers',
};

export const MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP = {
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.NEW]: 'New',
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.SIEVED]: 'Sieved',
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.DONE]: 'Done',
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNKNOWN]: 'Unknown',
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNSIEVED]: 'Unsieved',
};

export const ALLOWED_RUN_STATUSES_FOR_TOOLING_STOCK_SCAN = [
  RUN_STATUS.queued,
  RUN_STATUS.queuedReady,
  RUN_STATUS.inProgress,
  RUN_STATUS.paused,
];

export const MODEL_UNITS = {
  AUTOMATIC: 'auto',
  MM: 'mm',
  CM: 'cm',
  M: 'm',
  INCHES: 'in',
  FEET: 'feet',
};

export const MODEL_UNIT_COEFFICIENTS = {
  [MODEL_UNITS.MM]: {
    [MODEL_UNITS.M]: 1000,
    [MODEL_UNITS.CM]: 10,
    [MODEL_UNITS.MM]: 1,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.00328084,
    [MODEL_UNITS.INCHES]: 0.039370078740157,
  },
  [MODEL_UNITS.CM]: {
    [MODEL_UNITS.M]: 0.01,
    [MODEL_UNITS.CM]: 1,
    [MODEL_UNITS.MM]: 10,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 0.0328084,
    [MODEL_UNITS.INCHES]: 0.39370078740157,
  },
  [MODEL_UNITS.M]: {
    [MODEL_UNITS.M]: 1,
    [MODEL_UNITS.CM]: 100,
    [MODEL_UNITS.MM]: 1000,
    // 1 mm = 0.039370078740157
    [MODEL_UNITS.FEET]: 3.28084,
    [MODEL_UNITS.INCHES]: 39.370078740157,
  },
  [MODEL_UNITS.INCHES]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.254,
    [MODEL_UNITS.CM]: 2.54,
    [MODEL_UNITS.MM]: 25.4,
    [MODEL_UNITS.FEET]: 0.08333,
    [MODEL_UNITS.INCHES]: 1,
  },
  [MODEL_UNITS.FEET]: {
    // 1 in = 25.4 mm
    [MODEL_UNITS.M]: 0.3048,
    [MODEL_UNITS.CM]: 30.48,
    [MODEL_UNITS.MM]: 304.8,
    [MODEL_UNITS.FEET]: 1,
    [MODEL_UNITS.INCHES]: 12,
  },
};

export const MATERIAL_UNITS = {
  GRAM: 'gram',
  KG: 'kg',
  LB: 'lb',
  MM3: 'mm3',
  CM3: 'cm3',
  L: 'l',
  ML: 'ml',
  INCH3: 'inch3',
};

export const MEASUREMENT_SYSTEM = {
  IMPERIAL: 'imperial',
  METRIC: 'metric',
  CUSTOM: 'custom',
};

export const METRIC_UNITS = [
  MATERIAL_UNITS.GRAM,
  MATERIAL_UNITS.KG,
  MATERIAL_UNITS.MM3,
  MATERIAL_UNITS.CM3,
  MATERIAL_UNITS.L,
  MATERIAL_UNITS.ML,
];

export const IMPERIAL_UNITS = [MATERIAL_UNITS.LB, MATERIAL_UNITS.INCH3];

export const VOLUME_UNITS = [
  MATERIAL_UNITS.MM3,
  MATERIAL_UNITS.CM3,
  MATERIAL_UNITS.L,
  MATERIAL_UNITS.ML,
  MATERIAL_UNITS.INCH3,
];

export const WEIGHT_UNITS = [MATERIAL_UNITS.GRAM, MATERIAL_UNITS.KG, MATERIAL_UNITS.LB];

export const METRIC_DEFAULT_UNITS = {
  volume: MATERIAL_UNITS.CM3,
  weight: MATERIAL_UNITS.KG,
};

export const IMPERIAL_DEFAULT_UNITS = {
  volume: MATERIAL_UNITS.INCH3,
  weight: MATERIAL_UNITS.LB,
};

export const CONVERT_UNITS_MAP = {
  [MATERIAL_UNITS.GRAM]: 'g',
  [MATERIAL_UNITS.KG]: 'kg',
  [MATERIAL_UNITS.LB]: 'lb',
  [MATERIAL_UNITS.MM3]: 'mm3',
  [MATERIAL_UNITS.CM3]: 'cm3',
  [MATERIAL_UNITS.L]: 'l',
  [MATERIAL_UNITS.ML]: 'ml',
  [MATERIAL_UNITS.INCH3]: 'in3',
};

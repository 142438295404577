import _isEmpty from 'lodash/isEmpty';
import { api } from 'src/utils/api';
import {
  API_RESOURCES,
  MATERIAL_BATCH_ACTIONS,
  PERMANENT_CONTAINER_ACTIONS,
} from 'src/utils/constants';
import { getUuid } from 'src/utils/url';

/* eslint-disable camelcase */

/**
 * Constructs the API URL for a material batch based on the provided parameters.
 *
 * @param {Object} permanentContainer - An object representing the permanent container, which may contain a `current_batch` property.
 * @param {string} [batchUUID]
 * @param {string} [initialBatchUUID]
 * @returns {string|null} - The constructed API URL for the material batch. Returns `null` if no valid batch UUID can be determined.
 */
export function getBatchApiUrl(permanentContainer, batchUUID, initialBatchUUID) {
  if (batchUUID) return `${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`;
  if (initialBatchUUID) return `${API_RESOURCES.MATERIAL_BATCH}/${initialBatchUUID}/`;
  if (permanentContainer?.current_batch)
    return `${API_RESOURCES.MATERIAL_BATCH}/${getUuid(permanentContainer.current_batch)}/`;
  return null;
}

const executeAction = async ({
  endpoint,
  actionType,
  sourceBatch,
  sourceContainer,
  metadata,
  quantity,
}) => {
  const payload = {
    action_type: actionType,
    ...(sourceBatch && { source_batch: sourceBatch }),
    ...(sourceContainer && { source_material_container: sourceContainer }),
    ...(quantity !== undefined && { quantity: quantity }),
    metadata: metadata,
  };

  try {
    const response = await api.post(endpoint, { json: payload }).json();
    return response;
  } catch (error) {
    let errorMessage = 'Something went wrong while executing the action.';

    const errorData = error.response ? await error.response.json() : null;

    if (errorData?.errors?.length) {
      errorMessage = errorData.errors[0].title || errorMessage;
    }

    throw new Error(errorMessage);
  }
};

export const fullPrinterLoadAction = async (
  batchUri,
  sourceContainerUri,
  destinationPrinterUri
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.MACHINE_LOAD,
    sourceContainer: sourceContainerUri,
    sourceBatch: batchUri,
    metadata: { destination_printer: destinationPrinterUri },
  });
};

export const relocateContainerAction = async (
  locationUriToRelocate,
  subLocationUriToRelocate,
  containerUriToRelocate,
  sourceBatchUri
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.RELOCATE,
    sourceContainer: containerUriToRelocate,
    sourceBatch: sourceBatchUri,
    metadata: {
      destination_location: locationUriToRelocate,
      destination_sub_location: subLocationUriToRelocate,
    },
  });
};

export const relocateBatchAction = async (
  locationUriToRelocate,
  subLocationUriToRelocate,
  sourceBatchUri
) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.RELOCATE,
    sourceBatch: sourceBatchUri,
    metadata: {
      destination_location: locationUriToRelocate,
      destination_sub_location: subLocationUriToRelocate,
    },
  });
};

export const fullContainerLoadAction = async (
  batchUri,
  action,
  sourceContainerUri,
  quantity,
  sourceContainerBatchHasMultipleContainers,
  containerToLoadUri
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: action,
    sourceContainer: sourceContainerUri,
    sourceBatch: batchUri,
    ...(quantity && { quantity: quantity }),
    quantity: quantity,
    metadata: {
      // If source container has multiple containers, we need to specify which container to load
      ...(sourceContainerBatchHasMultipleContainers && { container_to_load: containerToLoadUri }),
    },
  });
};

export const batchMachineLoadAction = async (batchUri, action, printerUri) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: action,
    sourceBatch: batchUri,
    metadata: {
      printer: printerUri,
    },
  });
};

export const partialPrinterLoadAction = async (
  sourceContainerUri,
  sourceBatchUri,
  destinationPrinterUri,
  quantity
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
    sourceContainer: sourceContainerUri,
    sourceBatch: sourceBatchUri,
    metadata: {
      destination_printer: destinationPrinterUri,
      quantity: quantity,
    },
  });
};

export const partialLoadContainerAction = async (
  sourceContainerUri,
  sourceBatchUri,
  destinationContainerUri,
  quantity
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
    sourceContainer: sourceContainerUri,
    sourceBatch: sourceBatchUri,
    metadata: {
      destination_container: destinationContainerUri,
      quantity: quantity,
    },
  });
};

export const partialLoadEmptyBatchAction = async (
  quantity,
  batchUri,
  isLoadPrinterAction,
  destinationPrinterUri,
  destinationContainerUri
) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
    sourceBatch: batchUri,
    metadata: {
      amount_to_split: quantity,
      ...(isLoadPrinterAction
        ? { destination_printer: destinationPrinterUri }
        : { destination_container: destinationContainerUri }),
    },
  });
};

export const fullLoadEmptyBatchAction = async (
  destinationPrinterUri,
  isTopOff,
  batchUri,
  destinationPrinterBatchUri
) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: isTopOff
      ? MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF
      : MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
    sourceBatch: isTopOff ? destinationPrinterBatchUri : batchUri,
    metadata: {
      printer: destinationPrinterUri,
      ...(isTopOff && { batch_to_load: batchUri }),
    },
  });
};

export const loadMultipleContainersIntoDestinationAction = async (
  containersToSplit,
  batchUri,
  destinationContainerUri,
  destinationPrinterUri
) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
    sourceBatch: batchUri,
    metadata: {
      containers_to_split: containersToSplit,
      ...(!destinationPrinterUri &&
        destinationContainerUri && { destination_container: destinationContainerUri }),
      ...(destinationPrinterUri &&
        !destinationContainerUri && { destination_printer: destinationPrinterUri }),
    },
  });
};

export const splitMultipleContainersNoDestinationFromBatchAction = async (
  containersToSplit,
  batchUri
) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
    sourceBatch: batchUri,
    metadata: {
      containers_to_split: containersToSplit,
    },
  });
};

export const splitBatchAction = async (batchUri, quantity, selectedContainers = []) => {
  const metadata = {};
  if (!_isEmpty(selectedContainers)) {
    metadata.containers_to_split = selectedContainers.map(containerUri => ({ uri: containerUri }));
  } else {
    metadata.amount_to_split = quantity;
  }

  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.SPLIT_BATCH,
    sourceBatch: batchUri,
    metadata,
  });
};

export const sieveFullBatchAction = async (batchUri, quantity) => {
  return executeAction({
    endpoint: 'material-batch-action/',
    actionType: MATERIAL_BATCH_ACTIONS.SIEVE,
    sourceBatch: batchUri,
    metadata: { remaining_quantity: quantity },
  });
};

export const splitContainerByQuantity = async (
  batchUri,
  sourceContainerUri,
  quantityToSplit,
  destinationContainerUri
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.SPLIT_CONTAINER,
    sourceContainer: sourceContainerUri,
    sourceBatch: batchUri,
    metadata: {
      ...(destinationContainerUri && { destination_container: destinationContainerUri }),
      quantity: quantityToSplit,
    },
  });
};

// This will sieve the container and load into destination printer / container
export const sieveFullContainerAndLoad = async (
  sourceContainerBatchUri,
  sourceMaterialContainerUri,
  quantityToSieve,
  destinationContainerUri,
  printerUri
) => {
  return executeAction({
    endpoint: 'material-container-action/',
    actionType: PERMANENT_CONTAINER_ACTIONS.SIEVE,
    sourceContainer: sourceMaterialContainerUri,
    sourceBatch: sourceContainerBatchUri,
    metadata: {
      remaining_quantity: quantityToSieve,
      ...(printerUri ? { target_printer: printerUri } : {}),
      ...(!printerUri && destinationContainerUri
        ? { target_container: destinationContainerUri }
        : {}),
    },
  });
};

export const sieveAndLoadPartialEmptyBatchAction = async (
  sourceContainerBatchUri,
  quantity,
  isTopOff,
  destinationContainerUri,
  markSieveSuccess
) => {
  // Split Source Batch by Quantity and get New Batch
  const splitResponse = await splitBatchAction(sourceContainerBatchUri, quantity);

  const newBatchUri = splitResponse?.metadata?.split_off_batch;
  const newBatchQuantity = splitResponse?.metadata?.amount_to_split;

  // Sieve Full Batch by its Quantity
  await sieveFullBatchAction(newBatchUri, newBatchQuantity);
  // Load Full Batch into Destination Container
  await fullContainerLoadAction(
    newBatchUri,
    isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
    destinationContainerUri,
    newBatchQuantity
  );
  markSieveSuccess();
};

export const sieveAndLoadPartialContainerAction = async (
  isPrinterLoad,
  sourceContainerBatchUri,
  sourceContainerUri,
  quantity,
  isTopOff,
  destinationPrinterUri,
  destinationContainerUri,
  markSieveSuccess
) => {
  // Split Source Batch by Quantity and get New Batch
  const splitContainerResponse = await splitContainerByQuantity(
    sourceContainerBatchUri,
    sourceContainerUri,
    quantity
  );

  const newBatchUri = splitContainerResponse?.metadata?.created_material_batch;
  const newBatchQuantity = splitContainerResponse?.metadata?.quantity;

  // Sieve Full Batch by its Quantity
  await sieveFullBatchAction(newBatchUri, newBatchQuantity);

  if (isPrinterLoad) {
    await batchMachineLoadAction(
      newBatchUri,
      isTopOff ? MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF : MATERIAL_BATCH_ACTIONS.MACHINE_LOAD,
      destinationPrinterUri
    );
  } else {
    // Load Full Batch into Destination Container
    await fullContainerLoadAction(
      newBatchUri,
      isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
      destinationContainerUri,
      newBatchQuantity
    );
  }
  markSieveSuccess();
};

export const sieveMultipleContainersAndLoadAction = async (
  containersToSplit,
  batchUri,
  destinationContainerUri,
  isTopOff,
  markSieveSuccess
) => {
  // Split Source Batch by Containers and get New Batch
  const splitResponse = await splitMultipleContainersNoDestinationFromBatchAction(
    containersToSplit,
    batchUri
  );

  const newBatchUri = splitResponse?.metadata?.split_off_batch;

  const newBatch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${getUuid(newBatchUri)}/`).json();
  const newBatchQuantity = newBatch?.quantity;

  // Sieve Full Batch by its Quantity
  await sieveFullBatchAction(newBatchUri, newBatchQuantity);
  // Load Full Batch into Destination Container
  await fullContainerLoadAction(
    newBatchUri,
    isTopOff ? PERMANENT_CONTAINER_ACTIONS.TOP_OFF : PERMANENT_CONTAINER_ACTIONS.LOAD_MATERIAL,
    destinationContainerUri,
    newBatchQuantity
  );
  markSieveSuccess();
};

import {
  faArrowsSpin,
  faArrowUpShortWide,
  faLocationDot,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import { faCubes, faExclamationCircle, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import TruncatedTitle from 'src/components/TruncatedTitle';
import routes from 'src/utils/routes';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';

const PrinterCard = ({
  batch,
  customLocation,
  subLocation,
  printer,
  isError,
  customQuantity,
  customMainStyle,
  canRenderWithoutBatch,
}) => {
  const [locationShown, setLocationShown] = React.useState(false);

  if (!batch && !canRenderWithoutBatch) return null;

  const materials = !_isEmpty(batch?.materials)
    ? batch.materials.map(material => material.name).join(', ')
    : [];

  if (isError) {
    return (
      <p className='error-loading-card-resource'>
        <FontAwesomeIcon icon={faExclamationCircle} className='d-block spacer-right danger-color' />
        An error occurred while loading the Details.
      </p>
    );
  }

  const renderItemWithTooltipByCondition = (text, icon, shouldShowTooltip) => (
    <p className='mb0 truncated-materials-success-page'>
      <FontAwesomeIcon className='spacer-right' icon={icon} />
      {shouldShowTooltip ? (
        <OverlayTrigger placement='top' overlay={<Tooltip>{text}</Tooltip>}>
          <span>{text}</span>
        </OverlayTrigger>
      ) : (
        <span>{text}</span>
      )}
    </p>
  );

  const toggleLocation = () => setLocationShown(previous => !previous);

  return (
    <div className='material-batch-card-details' style={customMainStyle}>
      <div className='material-batch-card-details-header'>
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon className='spacer-right' icon={faPrint} />
          <p>
            <Link to={getRouteURI(routes.printerMaterial, { uuid: getUuid(printer.uri) })}>
              <TruncatedTitle
                inline
                title={printer.name}
                titleClassName='material-batch-card-details-header-batch font-medium mb0'
                maxTextLength={25}
                maxTextLengthMobile={20}
              />
            </Link>
          </p>
        </div>
      </div>
      <div className='material-batch-card-details-body'>
        {!batch && <h6>Machine is empty</h6>}
        {batch && (
          <div className='d-flex align-items-center justify-content-between mb8'>
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon
                className='spacer-right material-batch-card-details-icon-size'
                icon={faCubes}
              />
              <Link to={getRouteURI(routes.materialContainer, {}, { batch: batch.uuid })}>
                <p className='material-batch-card-details-header-batch mb0'>
                  {getShortUuid(batch.uuid)}
                </p>
              </Link>
            </div>
            <p className='mb0'>
              <FontAwesomeIcon className='spacer-right' icon={faArrowUpShortWide} />
              <ConvertedUnits quantity={customQuantity || batch?.quantity} units={batch.units} />
            </p>
          </div>
        )}
        {batch && (
          <div className='d-flex align-items-center justify-content-between'>
            {renderItemWithTooltipByCondition(materials, faObjectGroup, materials.length > 30)}
            <p className='mb0'>
              <FontAwesomeIcon className='spacer-right' icon={faArrowsSpin} />
              {batch.usage_cycles}
            </p>
          </div>
        )}
        <div className='material-batch-card-details-location'>
          <p className='mb0 badge badge-secondary' onClick={toggleLocation}>
            {!locationShown ? 'Show' : 'Hide'} Location
          </p>
        </div>
        {locationShown ? (
          <div
            style={{
              textAlign: 'left',
              marginTop: '10px',
            }}
          >
            <div>
              <div className='d-flex align-items-center'>
                <FontAwesomeIcon className='spacer-right' icon={faLocationDot} />
                <p className='mb0'>{customLocation || batch.location_name}</p>
              </div>
              <p className='mb0 badge' style={{ paddingLeft: 0, marginLeft: '19px' }}>
                {subLocation}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

PrinterCard.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string,
    units: PropTypes.string,
    status: PropTypes.string,
    quantity: PropTypes.number,
    // eslint-disable-next-line camelcase
    location_name: PropTypes.string,
    // eslint-disable-next-line camelcase
    powder_quality: PropTypes.string,
    // eslint-disable-next-line camelcase
    usage_cycles: PropTypes.number,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }),
  permanentContainer: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    uri: PropTypes.string,
  }),
  printer: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
  }),
  customLocation: PropTypes.string,
  subLocation: PropTypes.string,
  customQuantity: PropTypes.number,
  customMainStyle: PropTypes.shape({}),
  isError: PropTypes.bool,
  canRenderWithoutBatch: PropTypes.bool,
};

PrinterCard.defaultProps = {
  batch: null,
  permanentContainer: null,
  printer: null,
  customLocation: null,
  subLocation: null,
  customQuantity: null,
  customMainStyle: {},
  isError: false,
  canRenderWithoutBatch: false,
};

export default PrinterCard;

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import RangeInputConversionTooltip from 'src/components/RangeInputConversionTooltip';
import useSelectedAmountHandler from 'src/hooks/useSelectedAmountHandler';
import { convertToUserUnits, formatTwoDecimalsNumber } from 'src/utils/conversions';
import { formatConvertedUnits } from 'src/utils/ui';
import { formatDynamicPercentage } from 'src/utils/validation';

const ContainerQuantitySingleInput = ({
  sourceContainer,
  destinationResource,
  batchUnits,
  initialQuantity,
  setRemainingQuantityHandler,
  isNotSupportedByMaterials,
  initialContainerQuantity,
  setSelectedQuantity,
}) => {
  const { quantity: initialContainerQuantityConverted } = useMemo(
    () => convertToUserUnits(initialContainerQuantity, batchUnits),
    [initialContainerQuantity, batchUnits]
  );

  const {
    quantity: convertedContainerQuantity,
    units: convertedBatchUnits,
    isConverted,
  } = convertToUserUnits(sourceContainer.quantity, batchUnits);

  const {
    selectedAmountBase,
    selectedAmountConverted,
    handleSelectedAmountChange,
    handleRangeChange,
  } = useSelectedAmountHandler(
    sourceContainer.quantity,
    batchUnits,
    convertedContainerQuantity,
    convertedBatchUnits,
    initialContainerQuantity || 0,
    initialContainerQuantityConverted || 0
  );

  useEffect(() => {
    if (setRemainingQuantityHandler) {
      setRemainingQuantityHandler(sourceContainer.quantity - selectedAmountBase);
    }
  }, [selectedAmountBase]);

  useEffect(() => {
    // Set the selected quantity for the payload
    setSelectedQuantity(selectedAmountBase);
  }, [selectedAmountBase]);

  const containerRemainingPercentage = formatDynamicPercentage(selectedAmountBase, initialQuantity);

  return (
    <>
      <p className='mb0 d-flex align-items-center justify-content-center'>
        <strong>{destinationResource.name}</strong>
        {!!isNotSupportedByMaterials && (
          <FontAwesomeIcon icon={faExclamationTriangle} className='d-block ml-2 warning-color' />
        )}
      </p>
      <div className='alert'>
        <div className='d-flex align-items-center justify-content-center'>
          <div className='mb10 d-flex align-items-center'>
            <input
              name='remainingQuantity'
              min='0'
              max={selectedAmountConverted}
              value={selectedAmountConverted}
              className='unload-hopper-input'
              type='number'
              placeholder='Enter Remaining Quantity'
              onChange={handleSelectedAmountChange}
            />
            <div className='unload-hopper-input-input-text'>
              <p className='mb0 mr5'>{convertedBatchUnits}</p>&nbsp;
              <p className={`mb0 ${selectedAmountBase < 0 ? 'text-danger' : ''}`}>
                ({containerRemainingPercentage}%)
              </p>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center mt15'>
          <div>
            <div className='mb5'>0 ({batchUnits})</div>
            <div>{isConverted && formatConvertedUnits(0, convertedBatchUnits)}</div>
          </div>
          <div className='position-relative'>
            <RangeInputConversionTooltip
              className='rangeInputAbsolute'
              visible={isConverted}
              defaultUnits={batchUnits || ''}
              convertedUnits={convertedBatchUnits}
            />
            <input
              name='remainingQuantityRange'
              min='0'
              className='unload-hopper-input-slider'
              step='0.01'
              max={sourceContainer.quantity}
              type='range'
              value={selectedAmountBase}
              onChange={e => handleRangeChange(e.target.value)}
            />
          </div>
          <div>
            <div className='mb5'>
              {formatTwoDecimalsNumber(sourceContainer.quantity)} ({batchUnits})
            </div>
            <div>
              {isConverted && formatConvertedUnits(selectedAmountConverted, convertedBatchUnits)}
            </div>
          </div>
        </div>
      </div>
      <hr className='flex-grow-1' />
    </>
  );
};

ContainerQuantitySingleInput.propTypes = {
  sourceContainer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    maxQuantity: PropTypes.number.isRequired,
  }).isRequired,
  destinationResource: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  batchUnits: PropTypes.string.isRequired,
  initialQuantity: PropTypes.number.isRequired,
  setRemainingQuantityHandler: PropTypes.func.isRequired,
  setSelectedQuantity: PropTypes.func.isRequired,
  isNotSupportedByMaterials: PropTypes.bool.isRequired,
  initialContainerQuantity: PropTypes.number.isRequired,
};

export default ContainerQuantitySingleInput;

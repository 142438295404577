import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConvertedUnits from 'src/components/ConvertedUnits';
import Loader from 'src/components/loader';
import NotFound from 'src/components/not-found';
import useActionLoadingStore from 'src/stores/useActionLoadingStore';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { convertToUserUnits } from 'src/utils/conversions';
import useQueryParams, { getRouteURI, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';
import { handleConvertedQuantityChange, validateMultipleZeros } from 'src/utils/validation';

import routes from '../../../../utils/routes';
import ActionPage from './_action-wrapper';

const UnloadReclaimedMaterial = ({ user }) => {
  const { uuid: batchUUID } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { isLoading, setLoading } = useActionLoadingStore();
  const { actionFromPrinter } = useQueryParams();
  const [pageState, setPageState] = useState({
    id: batchUUID,
    batch: null,
    error: null,
  });
  const [subLocation, setSubLocation] = useState(undefined);
  const [unloadedQuantityBase, setUnloadedQuantityBase] = useState(0);
  const [unloadedQuantityConverted, setUnloadedQuantityConverted] = useState('');

  const navigate = useNavigate();

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const batch = await api.get(`${API_RESOURCES.MATERIAL_BATCH}/${batchUUID}/`).json();
      const subLocation = await api.get(`${API_RESOURCES.SUB_LOCATION}/${getUuid(batch.sub_location)}/`).json();
      setPageState((prevState) => ({
        ...prevState,
        id: batchUUID,
        batch,
      }));
      setSubLocation(subLocation);

      const quantityInMachine = batch.at_machine ? batch.quantity : 0;
      setUnloadedQuantityBase(quantityInMachine);

      // Convert the initial unloaded quantity to user's preferred units
      const conversionResult = convertToUserUnits(quantityInMachine, batch.units);
      setUnloadedQuantityConverted(conversionResult.quantity);

    } catch (error) {
      if (error.name === 'HTTPError') {
        setPageState((prevState) => ({
          ...prevState,
          id: batchUUID,
          error: {
            name: error.name,
            status: error.response && error.response.status,
          },
        }));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, [batchUUID]);

  // Convert batch quantity to user's preferred units
  const { quantity: convertedBatchQuantity, units: convertedBatchUnits } =
    useMemo(() => {
      if (pageState.batch) {
        return convertToUserUnits(pageState.batch.quantity, pageState.batch.units);
      }
      return { quantity: '0.00', units: '', isConverted: false };
    }, [pageState.batch]);


  const handleUnloadedQuantityChange = (e) => {
    handleConvertedQuantityChange({
      e,
      setBaseQuantity: setUnloadedQuantityBase,
      setConvertedQuantity: setUnloadedQuantityConverted,
      batchQuantity: pageState.batch.quantity,
      batchUnits: pageState.batch.units,
      convertedBatchQuantity,
      convertedBatchUnits,
      validateMultipleZeros,
    });
  };

  const remainingQuantity = pageState.batch ? pageState.batch.quantity - unloadedQuantityBase : 0;
  const unloadedQuantityPercentage =
    pageState.batch && pageState.batch.quantity
      ? Math.round((unloadedQuantityBase / pageState.batch.quantity) * 100)
      : 0;


  const onActionUnloadedReclaimedMaterial = async (batchUri) => {
    let actionResult = null;
    try {
      const payload = {
        /* eslint-disable camelcase */
        metadata: {
          unload_amount: unloadedQuantityBase,
          unload_type: 'reclaimed',
        },
        source_batch: batchUri,
        action_type: MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER,
        /* eslint-enable camelcase */
      };
      setSubmitting(true);
      actionResult = await materialActionRequest(payload).json();
    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      setSubmitting(false);
      return;
    }

    const { metadata: { resulting_batch: unloadedBatch } } = actionResult;

    navigate(getRouteURI(routes.materialBatchSuccess,
      { uuid: getUuid(unloadedBatch) },
      {
        action: MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL,
        batch: getUuid(unloadedBatch),
        quantity: unloadedQuantityBase,
        remainingQuantity,
        subLocation: subLocation.name,
        printer: pageState.batch.at_machine,
        ...(actionFromPrinter && { actionFromPrinter: true }),
      }));

    return new Promise(() => {
    });
  };

  const materialActionRequest = (payload) => {
    return api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
      json: payload,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!isLoading && !pageState.batch) {
    return <NotFound id={batchUUID} />;
  }

  return (
    <ActionPage
      id={pageState.id}
      user={user}
      httpError={pageState.error}
      customErrorText={submitError}
      action={MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL}
    >
      <div className="alert alert-warning" role="alert">
        <b>Amount of material originally loaded into machine:</b>&nbsp;
        <ConvertedUnits quantity={pageState?.batch?.quantity} units={pageState?.batch?.units} />
      </div>
      <div className="alert">
        <div>
          <label>Remove all reclaimed material from the build chamber and report the amount here:</label>
          &nbsp;<input
            name="remainingQuantity"
            min="0"
            style={{ width: '70px' }}
            max={convertedBatchQuantity}
            type="number"
            value={unloadedQuantityConverted}
            onChange={handleUnloadedQuantityChange}
        />
          &nbsp;{convertedBatchUnits}
          <span className={remainingQuantity < 0 ? 'text-danger' : ''}>
            &nbsp;({unloadedQuantityPercentage}%)
          </span>
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-lg btn-primary btn-block"
        disabled={!pageState.batch.at_machine || isSubmitting || remainingQuantity < 0 || !unloadedQuantityBase}
        onClick={() => onActionUnloadedReclaimedMaterial(pageState.batch.uri)}
      >
        Save
      </button>
    </ActionPage>
  );
};

UnloadReclaimedMaterial.propTypes = {
  user: userPropType,
};

UnloadReclaimedMaterial.defaultProps = {
  user: null,
};

export default UnloadReclaimedMaterial;

import { faInfoCircle, faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'src/components/alert';
import { getContainerName, getContainerType } from 'src/utils/stringUtils';
import { getShortUuid } from 'src/utils/url';

const BatchMultipleContainersAlert = ({
  sourceBatch,
  destinationBatch,
  sourceContainer,
  destinationContainer,
  containerUriToRedirect,
}) => {
  const sourceBatchContainersLengthLessTwo = sourceBatch?.containers?.length < 2;
  const destinationBatchContainersLengthLessTwo = destinationBatch?.containers?.length < 2;

  // No need to show the notification, we need the batch and more than 1 container.
  if (
    !sourceBatch ||
    (sourceBatchContainersLengthLessTwo && !destinationBatch) ||
    (destinationBatchContainersLengthLessTwo && sourceBatchContainersLengthLessTwo)
  ) {
    return null;
  }

  const batchWording = (
    <span>
      Please note, the <strong>Batch ({getShortUuid(sourceBatch.uri)})</strong> has multiple
      containers. Proceeding with this action will split the selected container(-s) from the batch,
      resulting in the creation of a new batch.
    </span>
  );

  const sourceContainerWording = (
    <span>
      Please note, {getContainerType(sourceContainer)}{' '}
      <strong>{getContainerName(sourceContainer)}</strong> is not the only container for the&nbsp;
      <strong>Batch ({getShortUuid(sourceBatch.uri)})</strong>. Proceeding with this action will
      split the container from the batch, resulting in the creation of a new batch.
    </span>
  );

  const destinationContainerWording = (
    <span>
      Please note, {getContainerType(destinationContainer)}{' '}
      <strong>{getContainerName(destinationContainer)}</strong> is not the only container for
      the&nbsp;
      <strong>Batch ({getShortUuid(destinationBatch?.uri)})</strong>. Proceeding with this action
      will split the container from the batch, resulting in the creation of a new batch.
    </span>
  );

  const twoContainersBatchMultipleWording = (
    <span>
      Please note, both {getContainerType(sourceContainer)}{' '}
      <strong>{getContainerName(sourceContainer)}</strong> and{' '}
      {getContainerType(destinationContainer)}{' '}
      <strong>{getContainerName(destinationContainer)}</strong> are not the only containers for
      their batches. Proceeding with this action will split the containers from the batches,
      resulting in the creation of new batches.
    </span>
  );

  const renderWording = () => {
    if (
      sourceContainer &&
      destinationContainer &&
      !sourceBatchContainersLengthLessTwo &&
      !destinationBatchContainersLengthLessTwo
    ) {
      return twoContainersBatchMultipleWording;
    }

    if (sourceContainer && !sourceBatchContainersLengthLessTwo) {
      return sourceContainerWording;
    }

    if (destinationContainer && !destinationBatchContainersLengthLessTwo) {
      return destinationContainerWording;
    }

    return batchWording;
  };

  return (
    <Alert
      name='qr-instructions'
      variant='info'
      className='text-left d-flex align-items-center mt15'
    >
      <FontAwesomeIcon icon={faInfoCircle} className='font-size-22 mr15' />
      <div>
        <p className='font-medium mb0'>{renderWording()}</p>
        {sourceContainer && containerUriToRedirect && !destinationContainer && (
          <p className='mb0 mt10'>
            Click&nbsp;
            <Link to={containerUriToRedirect}>
              <button
                type='button'
                className='btn btn-link notification-link btn-sm alert-link font-size-16'
              >
                here
                <FontAwesomeIcon color='#0b5460' className='spacer-left' icon={faLink} />
              </button>
            </Link>
            &nbsp;to get to the containers selection page.
          </p>
        )}
      </div>
    </Alert>
  );
};

BatchMultipleContainersAlert.defaultProps = {
  sourceContainer: null,
  destinationBatch: null,
  destinationContainer: null,
  containerUriToRedirect: '',
};

BatchMultipleContainersAlert.propTypes = {
  sourceBatch: PropTypes.shape({
    containers: PropTypes.arrayOf(PropTypes.shape({})),
    uri: PropTypes.string,
  }).isRequired,
  destinationBatch: PropTypes.shape({
    containers: PropTypes.arrayOf(PropTypes.shape({})),
    uri: PropTypes.string,
  }),
  sourceContainer: PropTypes.shape({
    uri: PropTypes.string,
  }),
  destinationContainer: PropTypes.shape({
    uri: PropTypes.string,
  }),
  containerUriToRedirect: PropTypes.string,
};

export default BatchMultipleContainersAlert;
